import React, { useCallback, useContext, useEffect, useState } from "react";
import AppContext from "../../../helpers/AppContext";
import { useTranslation } from "react-i18next";
import IngTextField from "../../atoms/IngTextField";
import IngSelector from "../../atoms/IngSelector";
import { openConfirmationDialog } from "../../../helpers/common";
import IngBotonera from "../../molecules/IngBotonera";
import moment from "moment";
import i18n from "../../../helpers/i18n";
import SpainFlag from "../../atoms/SpainFlag";
import UnitedKingdomFlag from "../../atoms/UnitedKingdomFlag";
import { Slider } from "@mui/material";
import IngSlider from "../../atoms/IngSlider";

const defaultErrors = {
  esName: undefined,
  enName: undefined,
  defaultRepetitions: undefined,
  repetitionTime: undefined,
  defaultSets: undefined,
  defaultRestBetweenSets: undefined,
  primaryMuscle: undefined,
};

function ExerciseForm({ exercise, muscles, update, remove }) {
  const { setGlobal } = useContext(AppContext);
  const { t } = useTranslation();
  const lang = i18n.language;

  const [exerciseForm, setExerciseForm] = useState(null);
  const [errors, setErrors] = useState(defaultErrors);
  const [selectedPrimaryMuscleId, setSelectedPrimaryMuscleId] = useState(0);

  const resetForm = useCallback(() => {
    setExerciseForm(exercise);
    setErrors(defaultErrors);
    setSelectedPrimaryMuscleId(exercise?.primaryMuscleId);
  }, [exercise]);

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  const errorControl = (name, value) => {
    var error;
    switch (name) {
      case "esName":
      case "enName":
        if (!value || value.trim() === "") error = t("common.helperTexts.name");
        break;
      case "primaryMuscle":
        if (!value) error = t("exercisePage.helperTexts.primaryMuscle");
        break;
      case "defaultRepetitions":
      case "repetitionTime":
      case "defaultSets":
      case "defaultRestBetweenSets":
        if (value === "") error = t("common.helperTexts.number");
        else if (value && !value.match(/^(\d+)$/))
          error = t("common.helperTexts.numberFormat");
        break;
      default:
        error = null;
        break;
    }

    if (error !== null) setErrors((old) => ({ ...old, [name]: error }));
  };

  const handleChange = (e) => {
    const name = e?.target?.name;
    var value = e?.target?.value;

    errorControl(name, value);

    if (name) setExerciseForm((old) => ({ ...old, [name]: value }));
  };

  const handleUpdate = () => {
    update(exerciseForm);
  };

  const handleRemove = () => {
    openConfirmationDialog({
      setGlobal,
      title: t("exercisePage.removeDialog.title"),
      text: t("exercisePage.removeDialog.text"),
      onConfirm: remove,
    });
  };

  const isDirty =
    exerciseForm &&
    Object.keys(exerciseForm).some(
      (key) => exerciseForm[key] !== exercise[key]
    );
  const isValid = !Object.keys(defaultErrors).some((key) => errors[key]);

  return (
    <>
      <div className="exercise-detail-leftcard-row">
        <IngTextField
          name="esName"
          label={
            <>
              {`${t("common.fields.name")}:`}
              <SpainFlag size="small" mode="label" />
            </>
          }
          value={exerciseForm?.esName}
          onChange={handleChange}
          errors={errors?.esName}
        />
        <IngTextField
          name="enName"
          label={
            <>
              {`${t("common.fields.name")}:`}
              <UnitedKingdomFlag size="small" mode="label" />
            </>
          }
          value={exerciseForm?.enName}
          onChange={handleChange}
          errors={errors?.enName}
        />
      </div>
      <div className="exercise-detail-leftcard-row">
        <IngTextField
          type="number"
          name="defaultRepetitions"
          label={`${t("fields.exercise.defaultRepetitions")}:`}
          value={exerciseForm?.defaultRepetitions}
          onChange={handleChange}
          errors={errors?.defaultRepetitions}
        />
        <IngTextField
          type="number"
          name="repetitionTime"
          label={`${t("fields.exercise.repetitionTime")}:`}
          value={exerciseForm?.repetitionTime}
          onChange={handleChange}
          endAdornment={t("common.seconds")}
          errors={errors?.repetitionTime}
        />
      </div>
      <div className="exercise-detail-leftcard-row">
        <IngTextField
          type="number"
          name="defaultSets"
          label={`${t("fields.exercise.defaultSets")}:`}
          value={exerciseForm?.defaultSets}
          onChange={handleChange}
          errors={errors?.defaultSets}
        />
        <IngTextField
          type="number"
          name="defaultRestBetweenSets"
          label={`${t("fields.exercise.defaultRestBetweenSets")}:`}
          value={exerciseForm?.defaultRestBetweenSets}
          onChange={handleChange}
          endAdornment={t("common.seconds")}
          errors={errors?.defaultRestBetweenSets}
        />
      </div>
      <div className="exercise-detail-leftcard-row">
        <IngSelector
          label={`${t("fields.exercise.primaryMuscle")}:`}
          options={muscles}
          getOptionLabel={(row) => row[`${lang}Name`]}
          groupBy={(row) => t(`dynamic.bodyZone.${row.bodyZone}`)}
          value={
            muscles.find((row) => row.id === exerciseForm?.primaryMuscleId) ||
            null
          }
          onChange={(e, newVal) => {
            errorControl("primaryMuscle", newVal?.id);
            setSelectedPrimaryMuscleId(newVal?.id);
            setExerciseForm((old) => ({
              ...old,
              primaryMuscleId: newVal?.id || null,
              musclesIds:
                old.musclesIds.filter((row) => row !== newVal?.id) || [],
            }));
          }}
          errors={errors?.primaryMuscle}
        />
        <IngSelector
          multiple={true}
          label={`${t("fields.exercise.muscles")}:`}
          options={muscles.filter((row) => row.id !== selectedPrimaryMuscleId)}
          getOptionLabel={(row) => row[`${lang}Name`]}
          groupBy={(row) => t(`dynamic.bodyZone.${row.bodyZone}`)}
          value={
            muscles.filter((row) =>
              exerciseForm?.musclesIds?.some((sub) => sub === row.id)
            ) || null
          }
          onChange={(e, newVal) =>
            setExerciseForm((old) => ({
              ...old,
              musclesIds: newVal.map((row) => row.id) || [],
            }))
          }
        />
      </div>
      <IngSlider
        label={`${t("fields.exercise.defaultLevel")}:`}
        name="defaultLevel"
        value={exerciseForm?.defaultLevel}
        onChange={handleChange}
        min={1}
        max={9}
      />
      <IngTextField
        name="videoUrl"
        value={exerciseForm?.videoUrl}
        label={`${t("fields.exercise.videoUrl")}:`}
        onChange={handleChange}
      />
      <div className="exercise-detail-leftcard-row">
        <IngTextField
          name="esDescription"
          label={
            <>
              {`${t("fields.exercise.description")}:`}
              <SpainFlag size="small" mode="label" />
            </>
          }
          value={exerciseForm?.esDescription}
          onChange={handleChange}
          multiline
          rows="4"
          errors={errors?.esDescription}
        />
        <IngTextField
          name="enDescription"
          label={
            <>
              {`${t("fields.exercise.description")}:`}
              <UnitedKingdomFlag size="small" mode="label" />
            </>
          }
          value={exerciseForm?.enDescription}
          onChange={handleChange}
          multiline
          rows="4"
          errors={errors?.enDescription}
        />
      </div>
      <div className="exercise-detail-leftcard-row">
        <IngTextField
          label={`${t("common.fields.createdBy")}:`}
          value={exerciseForm?.createdBy?.email}
          readOnly={true}
        />
        <IngTextField
          label={`${t("common.fields.creationDate")}:`}
          value={moment(exerciseForm?.creationDate).format("DD/MM/YYYY")}
          readOnly={true}
        />
      </div>
      <IngBotonera
        disableSave={!isDirty || !isValid}
        onClickSave={handleUpdate}
        onClickCancel={() => resetForm()}
        onClickDelete={handleRemove}
      />
    </>
  );
}

export default ExerciseForm;
