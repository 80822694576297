import React, { useState, useEffect, Suspense } from "react";
import { Routes, Route } from "react-router";
import "./helpers/Common.css";
import Home from "./components/pages/home/Home";
import UserList from "./components/pages/user/UserList";
import UserForm from "./components/pages/user/UserForm";
import Auth from "./Auth";
import Login from "./components/pages/login/Login";
import Verification from "./components/pages/verification/Verification";
import ResetPassword from "./components/pages/resetPassword/ResetPassword";
import Main from "./components/templates/Main";
import NotFound from "./components/pages/notFound/NotFound";
import About from "./components/pages/about/About";
import { roles } from "./helpers/common";
import MuscleList from "./components/pages/muscle/MuscleList";
import ExerciseList from "./components/pages/exercise/ExerciseList";
import ExerciseDetail from "./components/pages/exercise/ExerciseDetail";
import TensionPage from "./components/pages/tension/TensionPage";

export default function App() {
  const [token, setToken] = useState(false);

  const userRoles = Auth.getRoles();

  useEffect(() => {
    var aux = localStorage.getItem("token");
    aux ? setToken(true) : setToken(false);
    //para saltarme el login
    // setToken(true);
  }, [token]);

  /* Este useEffect se utiliza para ocultar el error de ResizeObserver 
  causado por los TextField multilinea de Material UI, no es por ninguna 
  incompatibilidad, es cosa de Material UI */
  useEffect(() => {
    const catchResizeObserver = (e) => {
      if (
        e.message ===
        "ResizeObserver loop completed with undelivered notifications."
      ) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    };

    window.addEventListener("error", catchResizeObserver);

    return () => window.removeEventListener("error", catchResizeObserver);
  }, []);

  return (
    <>
      <Suspense fallback="">
        {token ? (
          <Main userRoles={userRoles}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/ejercicios" element={<ExerciseList />} />
              <Route path="/ejercicios/:id" element={<ExerciseDetail />} />
              <Route path="/musculos" element={<MuscleList />} />
              <Route path="/tensiones" element={<TensionPage />} />
              <Route
                path="/usuarios"
                element={
                  userRoles.includes(roles.administrador) ? (
                    <UserList />
                  ) : (
                    <NotFound unauthorized />
                  )
                }
              />
              <Route
                path="/usuarios/:id"
                element={
                  userRoles.includes(roles.administrador) ? (
                    <UserForm />
                  ) : (
                    <NotFound unauthorized />
                  )
                }
              />
              <Route path="/about" element={<About />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Main>
        ) : (
          <Routes>
            <Route path="*" element={<Login />} />
            <Route path="/verification" element={<Verification />} />
            <Route path="/resetpass" element={<ResetPassword />} />
          </Routes>
        )}
      </Suspense>
    </>
  );
}
