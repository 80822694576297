import { baseUrl } from "../helpers/common";

function get(id) {
  const token = localStorage.getItem("token");
  let accessToken = JSON.parse(token);

  return fetch(`/api/image/${id}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken["token"]}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

function createBy(mode, id, formData) {
  const token = localStorage.getItem("token");
  let accessToken = JSON.parse(token);

  return fetch(
    `${baseUrl}/api/image/${mode}${mode !== "user" ? `/${id}` : ``}`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken["token"]}`,
      },
      body: formData,
    }
  );
}

function remove(id) {
  const token = localStorage.getItem("token");
  let accessToken = JSON.parse(token);

  return fetch(`/api/image/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${accessToken["token"]}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export { get, createBy, remove };
