import React from "react";
import { Controller } from "react-hook-form";
import IngTextField from "../atoms/IngTextField";

/**
 * Ing Textfield personalizado
 * @Component
 * @param {Object} props – parametros de la clase
 * @param {String} [props.className] - estilo personalizado del TextField
 * @param {Object} props.control - UseForm controlle
 * @param {String} props.name - UseForm field name
 * @param {"outlined" | "filled" | "standard" | "inherit" } [props.variant] - variant del TextField
 * @param {String} [props.label] - título del textfield
 * @param {"primary" | "secondary" | "error" | "warning" | "info" | "success" | "inherit"} [props.color] - color del TextField
 * @param {React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>} [props.onChangeCustom] - función onChange del TextField
 * @param {boolean} [props.disabled] - Flag para deshabilitar el TextField
 * @param {boolean} [props.readOnly] - Flag para que el TextField sea solo de lectura
 * @param {String} [props.placeholder] - placeholder del Textfield
 * @param {String} [props.errors] - texto de error para los validadores
 * @param {boolean} [props.multiline] - controla si el textfield es expandible en función del contenido
 * @param {React.KeyboardEventHandler<HTMLDivElement>} [props.onKeyPress] - función para enter
 * @param {number} [props.rows] - Numero de lineas que debe ocupar el textfield multilinea
 * @param {boolean} [props.notFullWidth] - Evita que el TextField sea fullWidth
 * @param {boolean} [props.isPassword] - Flag para saber si el input es para un password
 * @param {"text" | "email" | "number" | "tel" | "text" | "url"} [props.type] - Type para el input
 */

function IngControlledTextField({
  control,
  name,
  className,
  variant,
  label,
  color,
  onChangeCustom,
  disabled,
  readOnly,
  placeholder,
  errors,
  multiline,
  onKeyPress,
  rows,
  notFullWidth,
  isPassword,
  type,
}) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <IngTextField
          className={className}
          variant={variant}
          label={label}
          value={value}
          color={color}
          onChange={(e) => {
            onChange(e);
            onChangeCustom && onChangeCustom(e);
          }}
          disabled={disabled}
          readOnly={readOnly}
          placeholder={placeholder}
          errors={errors}
          multiline={multiline}
          onKeyPress={onKeyPress}
          rows={rows}
          notFullWidth={!notFullWidth}
          isPassword={isPassword}
          type={type}
        />
      )}
    />
  );
}

export default IngControlledTextField;
