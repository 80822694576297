import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "../../../helpers/AppContext";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import ClearIcon from "@mui/icons-material/Clear";
import IngTextField from "../../atoms/IngTextField";
import IngButton from "../../atoms/IngButton";
import { TensionService } from "../../../services";
import { normalRequestBehavior, openAlert } from "../../../helpers/common";
import IngSelector from "../../atoms/IngSelector";
import i18n from "../../../helpers/i18n";

const defaultTensionForm = {
  level: undefined,
  minWeight: undefined,
  maxWeight: undefined,
  tensionTypeId: undefined,
};

const defaultErrors = {
  esName: undefined,
  enName: undefined,
  defaultRepetitions: undefined,
  repetitionTime: undefined,
  defaultSets: undefined,
  defaultRestBetweenSets: undefined,
  primaryMuscle: undefined,
};

function TensionDialogForm({ isOpen, setClose, tension, types, updateList }) {
  const { t } = useTranslation();
  const lang = i18n.language;
  const { setGlobal } = useContext(AppContext);

  const [tensionForm, setTensionForm] = useState(defaultTensionForm);
  const [errors, setErrors] = useState(defaultErrors);

  useEffect(() => {
    if (tension) {
      setTensionForm(tension);
    }
  }, [tension]);

  const handleClose = () => {
    setClose();
    setTensionForm(defaultTensionForm);
    setErrors(defaultErrors);
  };

  const handleSave = async () => {
    let requestTension = {
      level: tensionForm.level,
      minWeight: tensionForm.minWeight,
      maxWeight: tensionForm.maxWeight,
      tensionTypeId: tensionForm.tensionTypeId,
    };

    TensionService.createOrUpdate(requestTension, tension?.id)
      .then(normalRequestBehavior)
      .then((data) => {
        if (typeof data === "string" && data.includes("dynamic.customErrors")) {
          openAlert(setGlobal, t(data), "error");
        } else {
          updateList();
          openAlert(
            setGlobal,
            tension?.id
              ? t("tensionPage.alert.updateSuccess")
              : t("tensionPage.alert.createSuccess"),
            "success"
          );
          handleClose();
        }
      })
      .catch((err) => {
        console.error(err);
        openAlert(setGlobal, t("tensionPage.alert.createError"), "error");
      });
  };

  const errorControl = (name, value) => {
    var error;
    switch (name) {
      case "level":
        if (value === "") error = t("common.helperTexts.number");
        else if (value && !value.match(/^(\d+)$/))
          error = t("common.helperTexts.intFormat");
        break;
      case "minWeight":
      case "maxWeight":
        if (value === "") error = t("common.helperTexts.number");
        else if (value && value.match(/^[+-]/))
          error = t("common.helperTexts.positiveNumber");
        break;
      case "tensionTypeId":
        if (!value) error = t("tensionPage.helperTexts.tenionTypeId");
        break;
      default:
        error = null;
        break;
    }

    if (error !== null) setErrors((old) => ({ ...old, [name]: error }));
  };

  const handleChange = (name, value) => {
    errorControl(name, value);

    if (name) setTensionForm((old) => ({ ...old, [name]: value }));
  };

  const isDirty = tension
    ? tensionForm &&
      Object.keys(tensionForm).some((key) => tensionForm[key] !== tension[key])
    : true;
  const isValid = !Object.keys(tensionForm).some(
    (key) => errors[key] || tensionForm[key] === undefined || null
  );

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        {tension?.id ? t("tensionPage.update") : t("tensionPage.create")}
      </DialogTitle>
      <DialogContent className="common-createDialog-container">
        <IngTextField
          label={`${t("fields.tension.level")}:`}
          value={tensionForm.level}
          type="number"
          onChange={(e) => handleChange("level", e.target.value)}
          errors={errors?.level}
        />
        <IngSelector
          label={`${t("fields.tensionType.tensionType")}:`}
          options={types}
          getOptionLabel={(row) => row[`${lang}Name`]}
          value={
            types?.find((row) => row.id === tensionForm.tensionTypeId) || null
          }
          onChange={(e, newVal) => handleChange("tensionTypeId", newVal?.id)}
          errors={errors?.tensionTypeId}
        />
        <IngTextField
          label={`${t("fields.tension.minWeight")}:`}
          value={tensionForm.minWeight}
          type="number"
          onChange={(e) => handleChange("minWeight", e.target.value)}
          errors={errors?.minWeight}
        />
        <IngTextField
          label={`${t("fields.tension.maxWeight")}:`}
          value={tensionForm.maxWeight}
          type="number"
          onChange={(e) => handleChange("maxWeight", e.target.value)}
          errors={errors?.maxWeight}
        />
      </DialogContent>
      <DialogActions>
        <IngButton
          onClick={handleSave}
          disabled={!isDirty || !isValid}
          label={t("common.confirm")}
          icon={<PanoramaFishEyeIcon />}
          color="success"
        />
        <IngButton
          onClick={() => handleClose()}
          label={t("common.cancel")}
          icon={<ClearIcon />}
          color="error"
        />
      </DialogActions>
    </Dialog>
  );
}

export default TensionDialogForm;
