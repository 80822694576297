import React, { useCallback, useContext, useEffect, useState } from "react";
import AppContext from "../../../helpers/AppContext";
import { useTranslation } from "react-i18next";
import {
  defaultLimit,
  defaultOrderBy,
  normalRequestBehavior,
  openAlert,
} from "../../../helpers/common";
import { ExerciseService, MuscleService } from "../../../services/index";
import IngBackdrop from "../../atoms/IngBackdrop";
import {
  Avatar,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  useTheme,
} from "@mui/material";
import { getThemeColors } from "../../../helpers/Colors";
import { useNavigate } from "react-router-dom";
import "./Exercise.css";
import ExerciseCreateDialog from "../../organism/exercise/ExerciseCreateDialog";
import CameraAltSharpIcon from "@mui/icons-material/CameraAltSharp";
import IngPaginationBackend from "../../molecules/IngPaginationBackend";
import i18n from "../../../helpers/i18n";
import moment from "moment";

function ExerciseList() {
  const { setGlobal } = useContext(AppContext);
  const lang = i18n.language;
  const { t } = useTranslation();
  const theme = useTheme();

  const navigate = useNavigate();

  const [limit, setLimit] = useState(defaultLimit);
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState(0);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);

  const [loading, setLoading] = useState(false);
  const [exerciseList, setExerciseList] = useState(undefined);
  const [muscles, setMuscles] = useState(undefined);

  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  const getExercises = useCallback(() => {
    setLoading(true);
    ExerciseService.getFiltered({ limit, offset, search, orderBy })
      .then(normalRequestBehavior)
      .then((data) => {
        if (data) {
          setExerciseList(data.exercises);
          setTotal(data.total);
        }
      })
      .catch((err) => {
        openAlert(setGlobal, t("exercisePage.alert.getAllError"), "error");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [limit, offset, orderBy, search, setGlobal, t]);

  const getMuscles = useCallback(() => {
    setLoading(true);
    MuscleService.getSelector()
      .then(normalRequestBehavior)
      .then((data) => setMuscles(data))
      .catch((err) => {
        console.error(err);
        openAlert(setGlobal, t("exercisePage.alert.getError"), "error");
      })
      .finally(() => setLoading(false));
  }, [setGlobal, t]);

  useEffect(() => {
    getExercises();
    getMuscles();
    setGlobal((prev) => ({ ...prev, pageTitle: t("exercisePage.title") }));
  }, [getExercises, getMuscles, setGlobal, t]);

  const handleOrderBy = (key) => {
    if (key === orderBy?.field)
      if (!orderBy?.ascendent) setOrderBy(defaultOrderBy);
      else setOrderBy({ field: key, ascendent: false });
    else setOrderBy({ field: key, ascendent: true });
  };

  return (
    <IngBackdrop loading={loading}>
      <IngPaginationBackend
        limit={limit}
        setLimit={setLimit}
        offset={offset}
        setOffset={setOffset}
        total={total}
        setSearch={setSearch}
        onAddButtonClick={() => setOpenCreateDialog(true)}
      />
      <Paper>
        <Table size="small">
          <TableHead
            style={{
              backgroundColor: getThemeColors(theme.palette.mode).background
                .paperLabel,
            }}
          >
            <TableRow>
              <TableCell>{t("fields.exercise.image")}</TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy?.field === "name"}
                  direction={orderBy?.ascendent ? "asc" : "desc"}
                  onClick={() => handleOrderBy("name")}
                >
                  {t("common.fields.name")}
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy?.field === "creationDate"}
                  direction={orderBy?.ascendent ? "asc" : "desc"}
                  onClick={() => handleOrderBy("creationDate")}
                >
                  {t("common.fields.creationDate")}
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy?.field === "primaryMuscle.name"}
                  direction={orderBy?.ascendent ? "asc" : "desc"}
                  onClick={() => handleOrderBy("primaryMuscle.name")}
                >
                  {t("fields.muscle.muscle")}
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy?.field === "primaryMuscle.bodyZone"}
                  direction={orderBy?.ascendent ? "asc" : "desc"}
                  onClick={() => handleOrderBy("primaryMuscle.bodyZone")}
                >
                  {t("fields.muscle.bodyZone")}
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {exerciseList?.length > 0 ? (
              exerciseList.map((row) => (
                <TableRow
                  key={row.id}
                  hover={true}
                  onClick={() => navigate(`/ejercicios/${row.id}`)}
                >
                  <TableCell>
                    {row.image?.base64 ? (
                      <Avatar
                        src={row?.image?.base64}
                        style={{ height: 40, width: 40 }}
                      />
                    ) : (
                      <Avatar style={{ height: 40, width: 40 }}>
                        <CameraAltSharpIcon />
                      </Avatar>
                    )}
                  </TableCell>
                  <TableCell>{row[`${lang}Name`]}</TableCell>
                  <TableCell>
                    {moment(row?.creationDate).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell>{row.primaryMuscle[`${lang}Name`]}</TableCell>
                  <TableCell>
                    {t(`dynamic.bodyZone.${row.primaryMuscle.bodyZone}`)}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={5}>
                  {t("dataTableProps.body.emptyDataSourceMessage")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>
      <ExerciseCreateDialog
        isOpen={openCreateDialog}
        setClose={() => setOpenCreateDialog(false)}
        muscles={muscles}
      />
    </IngBackdrop>
  );
}

export default ExerciseList;
