import * as React from "react";
import { Controller } from "react-hook-form";
import IngSelector from "../atoms/IngSelector";

/**
 * Ing Selector Básico personalizado
 * @Component
 * @param {Object} props – Parametros de la clase
 * @param {String} [props.className] - Estilo personalizado del selector
 * @param {boolean} [props.multiple] - Selector multiple o basico.
 * @param {any} props.control - UseForm controlle
 * @param {any} props.name - UseForm field name
 * @param {any[]} props.options - Array de opciones
 * @param {(option: any) => string} props.getOptionLabel - Parámetro que sirva de título de la opción del array de opciones
 * @param {any} [props.onChangeCustom] - Función onChange del selector
 * @param {String} props.label - Label para el textField del selector
 * @param {String} [props.errors] - Texto de error para validadores
 * @param {boolean} [props.desactivaBorrar] - Icono de borrado true para desabilitar
 * @param {any} [props.color] - Color del TextField
 * @param {boolean} [props.notFullWidth] - Evita que el TextField sea fullWidth
 * @param {boolean} [props.loading] - Loading flag
 * @param {boolean | "nav"} [props.readOnly]
 */

function IngControlledSelector({
  control,
  name,
  className,
  multiple,
  options,
  getOptionLabel,
  onChangeCustom,
  label,
  errors,
  desactivaBorrar,
  color,
  notFullWidth,
  readOnly,
}) {
  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <IngSelector
            className={className}
            multiple={multiple}
            options={options}
            getOptionLabel={getOptionLabel}
            value={value}
            onChange={(e, option) => {
              onChange(option);
              onChangeCustom && onChangeCustom(option);
            }}
            isOptionEqualToValue={(row, selected) =>
              !multiple
                ? getOptionLabel(row) === getOptionLabel(value)
                : row === selected
            }
            label={label}
            errors={errors}
            desactivaBorrar={desactivaBorrar}
            color={color}
            notFullWidth={notFullWidth}
            readOnly={readOnly}
          />
        )}
      />
    </>
  );
}

export default IngControlledSelector;
