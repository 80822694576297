import React from "react";
import CloudIcon from "@mui/icons-material/Cloud";
import { useTranslation } from "react-i18next";
import "./NotFound.css";
import Home from "../home/Home";
import { useTheme } from "@mui/material";
import { colors } from "../../../helpers/Colors";
import DoDisturbAltOutlinedIcon from '@mui/icons-material/DoDisturbAltOutlined';

/**
 *
 * @param {Object} props
 * @param {boolean} [props.unauthorized] - True: Unauthorised page, False: NotFound page.
 * @returns
 */
function NotFound({ unauthorized }) {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <>
      <div className="notFound-container">
        <div
          className="notFound-icon-container"
          style={{
            backgroundColor: theme.palette.primary.main,
            color: colors.whitesmoke,
          }}
        >
          {!unauthorized ? (
            <CloudIcon className="notFound-icon" />
          ) : (
            <DoDisturbAltOutlinedIcon className="notFound-icon" />
          )}
        </div>
        <div className="notFound-text">
          {!unauthorized ? t("common.404") : t("common.unauthorized")}
        </div>
      </div>
      <Home />
    </>
  );
}

export default NotFound;
