import React, { useContext, useEffect } from "react";
import ListIcon from "@mui/icons-material/List";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import IngTabs from "../../molecules/IngTabs";
import AppContext from "../../../helpers/AppContext";
import { useTranslation } from "react-i18next";
import TensionTypeList from "../../organism/tension/TensionTypeList";
import TensionList from "../../organism/tension/TensionList";

function TensionPage() {
  const { setGlobal } = useContext(AppContext);
  const { t } = useTranslation();

  useEffect(() => {
    setGlobal((prev) => ({
      ...prev,
      pageTitle: t("tensionPage.title"),
    }));
  }, [setGlobal, t]);

  return (
    <IngTabs
      tabs={[
        {
          label: t("tensionPage.tabs.list"),
          icon: <ListIcon />,
          content: <TensionList />,
        },
        {
          label: t("tensionPage.tabs.type"),
          icon: <UnfoldMoreIcon />,
          content: <TensionTypeList />,
        },
      ]}
    />
  );
}

export default TensionPage;
