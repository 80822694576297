import React, { useCallback, useEffect } from "react";
import "./Verification.css";
import IngButton from "../../atoms/IngButton";
import OfflineShareIcon from "@mui/icons-material/OfflineShare";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthService } from "../../../services";
import { baseUrl } from "../../../helpers/common";
import { CircularProgress, Paper, ThemeProvider } from "@mui/material";
import { colors, getTheme } from "../../../helpers/Colors";
import packageInfo from "../../../../package.json";
import { useSearchParams } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

function Verification() {
  //Borrar Token para renovarlo
  localStorage.removeItem("token");
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const email = searchParams.get("email");
  const [loading, setLoading] = useState(true);
  const [correct, setCorrect] = useState(false);

  const getVerification = useCallback(() => {
    setLoading(true);
    AuthService.verification(token, email)
      .then(async (response) => {
        if (response.status === 200) {
          setCorrect(true);
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((err) => {
        console.error(err);
        setCorrect(false);
      })
      .finally(() => setLoading(false));
  }, [email, token]);

  useEffect(() => {
    getVerification();
  }, [getVerification]);

  return (
    <ThemeProvider theme={getTheme(localStorage.getItem("theme"))}>
      <div
        className="verification-background"
        style={{
          background: `url('${baseUrl}/img/login-background.jpg')`,
          backgroundSize: "cover",
        }}
      >
        <Paper className="verification-card">
          <div className="verification-title">{t("verification.title")}</div>
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              alignContent: "center",
              textAlign: "center",
            }}
          >
            {loading ? (
              <>
                <CircularProgress
                  color="inherit"
                  className="verification-loading"
                />
                {t("verification.verifying")}
              </>
            ) : correct ? (
              <>
                <div>
                  <CheckCircleOutlineIcon className="verification-icon" />
                </div>
                <div>{t("verification.correctVerified")}</div>
                <IngButton
                  className="verification-button"
                  onClick={() => {
                    const appLink = "nemus://open";
                    //const fallbackLink = 'https://play.google.com/store/apps/details?id=com.example.nemus';

                    // Intenta abrir la aplicación
                    window.location.href = appLink;

                    // Si la aplicación no está instalada, espera un breve momento y redirige a la tienda de aplicaciones
                    // setTimeout(() => {
                    //   window.location.href = fallbackLink;
                    // }, 1000); // Espera 1 segundo antes de redirigir a la tienda de aplicaciones
                  }}
                  variant="contained"
                  label={t("verification.appButton")}
                  icon={
                    <OfflineShareIcon className="verification-iconButton" />
                  }
                />
              </>
            ) : (
              <>
                <div>
                  <ErrorOutlineIcon className="verification-icon" />
                </div>
                <div>{t("verification.errorVerified")}</div>
              </>
            )}
          </div>
        </Paper>
        <div
          className="verification-version-number"
          style={{
            backgroundColor: colors.apolo,
            color: colors.whitesmoke,
          }}
        >
          v{packageInfo.version}
        </div>
      </div>
    </ThemeProvider>
  );
}

export default Verification;
