function getAll() {
  const token = localStorage.getItem("token");
  let accessToken = JSON.parse(token);
  return fetch("/api/tensiontype", {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken["token"]}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

function getSelector() {
  const token = localStorage.getItem("token");
  let accessToken = JSON.parse(token);
  return fetch("/api/tensiontype/selector", {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken["token"]}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

async function createOrUpdate(tensionType, id) {
  const token = localStorage.getItem("token");
  let accessToken = JSON.parse(token);

  return fetch(`/api/tensiontype${id ? `/${id}` : ""}`, {
    method: id ? "PUT" : "POST",
    headers: {
      Authorization: `Bearer ${accessToken["token"]}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(tensionType),
  });
}

async function remove(id) {
  const token = localStorage.getItem("token");
  let accessToken = JSON.parse(token);

  return fetch(`/api/tensiontype/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${accessToken["token"]}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export { getAll, getSelector, createOrUpdate, remove };
