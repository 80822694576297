function getAll() {
  const token = localStorage.getItem("token");
  let accessToken = JSON.parse(token);
  return fetch("/api/tension", {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken["token"]}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

async function createOrUpdate(tension, id) {
  const token = localStorage.getItem("token");
  let accessToken = JSON.parse(token);

  return fetch(`/api/tension${id ? `/${id}` : ""}`, {
    method: id ? "PUT" : "POST",
    headers: {
      Authorization: `Bearer ${accessToken["token"]}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(tension),
  });
}

async function remove(id) {
  const token = localStorage.getItem("token");
  let accessToken = JSON.parse(token);

  return fetch(`/api/tension/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${accessToken["token"]}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export { getAll, createOrUpdate, remove };
