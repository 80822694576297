import React, { useCallback, useContext, useEffect, useState } from "react";
import AppContext from "../../../helpers/AppContext";
import { useTranslation } from "react-i18next";
import { normalRequestBehavior, openAlert } from "../../../helpers/common";
import { MuscleService } from "../../../services/index";
import IngBackdrop from "../../atoms/IngBackdrop";
import MaterialTable from "@material-table/core";
import { IconButton, useTheme } from "@mui/material";
import { colors, getThemeColors } from "../../../helpers/Colors";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import i18n from "../../../helpers/i18n";

function MuscleList() {
  const { setGlobal } = useContext(AppContext);
  const { t } = useTranslation();
  const lang = i18n.language;
  const theme = useTheme();

  const [loading, setLoading] = useState(false);
  const [muscleList, setMuscleList] = useState(undefined);

  const getMuscles = useCallback(() => {
    setLoading(true);
    MuscleService.getAll()
      .then(normalRequestBehavior)
      .then((data) => {
        setMuscleList(
          data.map((row) => ({
            ...row,
            transBodyZone: t(`dynamic.bodyZone.${row.bodyZone}`),
          }))
        );
      })
      .catch((err) => {
        openAlert(setGlobal, t("musclePage.alert.getAllError"), "error");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [setGlobal, t]);

  useEffect(() => {
    getMuscles();
    setGlobal((prev) => ({ ...prev, pageTitle: t("musclePage.title") }));
  }, [getMuscles, setGlobal, t]);

  const handleUpdateMuscle = (muscle) => {
    setLoading(true);
    let updatedMuscle = { ...muscle };

    updatedMuscle.isEnabled = !muscle.isEnabled;

    MuscleService.update(muscle.id, updatedMuscle)
      .then(normalRequestBehavior)
      .then((data) =>
        setMuscleList((old) =>
          [...old].map((row) =>
            row.id === data.id
              ? {
                  ...data,
                  transBodyZone: t(`dynamic.bodyZone.${data.bodyZone}`),
                }
              : row
          )
        )
      )
      .catch((err) => {
        console.error(err);
        openAlert(setGlobal, t("musclePage.alert.updateError"), "error");
      })
      .finally(() => setLoading(false));
  };

  const columns = [
    {
      title: t("common.fields.name"),
      field: `${lang}Name`,
    },
    {
      title: t("fields.muscle.bodyZone"),
      field: "transBodyZone",
      width: "2%",
      defaultGroupOrder: 0,
    },
    {
      title: t("fields.muscle.isEnabled"),
      field: "isEnabled",
      render: (row) => (
        <IconButton onClick={() => handleUpdateMuscle(row)}>
          {row.isEnabled ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
        </IconButton>
      ),
    },
  ];

  return (
    <IngBackdrop loading={loading}>
      <MaterialTable
        title={
          <div className="materialtable-title-container">
            <AccessibilityNewIcon style={{ color: colors.muscles }} />
            {t("musclePage.title")}
          </div>
        }
        columns={columns}
        data={muscleList || []}
        localization={JSON.parse(JSON.stringify(t("dataTableProps")))}
        options={{
          draggable: false,
          paging: false,
          padding: "dense",
          headerStyle: {
            backgroundColor: getThemeColors(theme.palette.mode).background
              .paperLabel,
            height: "50px",
          },
        }}
      />
    </IngBackdrop>
  );
}

export default MuscleList;
