import React, { useCallback, useContext, useEffect, useState } from "react";
import AppContext from "../../../helpers/AppContext";
import { useTranslation } from "react-i18next";
import {
  normalRequestBehavior,
  openAlert,
  openConfirmationDialog,
} from "../../../helpers/common";
import IngBackdrop from "../../atoms/IngBackdrop";
import MaterialTable from "@material-table/core";
import { IconButton, useTheme } from "@mui/material";
import { colors, getThemeColors } from "../../../helpers/Colors";
import { TensionService, TensionTypeService } from "../../../services";
import ExpandIcon from "@mui/icons-material/Expand";
import TensionDialogForm from "./TensionDialogForm";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import i18n from "../../../helpers/i18n";

function TensionList() {
  const { setGlobal } = useContext(AppContext);
  const lang = i18n.language;
  const { t } = useTranslation();
  const theme = useTheme();

  const [loading, setLoading] = useState(false);
  const [tensionList, setTensionList] = useState(undefined);
  const [tensionTypesList, setTensionTypesList] = useState(undefined);
  const [selectedTension, setSelectedTension] = useState(undefined);
  const [openDialog, setOpenDialog] = useState(false);

  const getTensions = useCallback(() => {
    setLoading(true);
    TensionService.getAll()
      .then(normalRequestBehavior)
      .then((data) => setTensionList(data))
      .catch((err) => {
        openAlert(setGlobal, t("tensionPage.alert.getAllError"), "error");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [setGlobal, t]);

  const getTensionTypes = useCallback(() => {
    setLoading(true);
    TensionTypeService.getSelector()
      .then(normalRequestBehavior)
      .then((data) => setTensionTypesList(data))
      .catch((err) => {
        openAlert(setGlobal, t("tensionPage.type.alert.getAllError"), "error");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [setGlobal, t]);

  useEffect(() => {
    getTensions();
    getTensionTypes();
  }, [getTensionTypes, getTensions]);

  const handleRemove = async (id) => {
    openConfirmationDialog({
      setGlobal,
      title: t("tensionPage.removeDialog.title"),
      text: t("tensionPage.removeDialog.text"),
      onConfirm: () => {
        setLoading(true);
        TensionService.remove(id)
          .then(normalRequestBehavior)
          .then(() => {
            getTensions();
            openAlert(
              setGlobal,
              t("tensionPage.alert.removeSuccess"),
              "success"
            );
          })
          .catch((err) => {
            openAlert(setGlobal, t("tensionPage.alert.removeError"), "error");
            console.error(err);
          })
          .finally(() => setLoading(false));
      },
    });
  };

  const columns = [
    {
      title: t("fields.tension.level"),
      field: "level",
    },
    {
      title: t("fields.tensionType.tensionType"),
      field: `tensionType.${lang}Name`,
    },
    {
      title: t("fields.tension.minWeight"),
      field: "minWeight",
    },
    {
      title: t("fields.tension.maxWeight"),
      field: "maxWeight",
    },
    {
      title: t("common.fields.createdBy"),
      field: "createdBy.email",
    },
    {
      title: t("common.fields.creationDate"),
      field: "creationDate",
      render: (row) => moment(row.creationDate).format("DD/MM/YYYY"),
    },
    {
      width: "40px",
      render: (row) => (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            handleRemove(row?.id);
          }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <IngBackdrop loading={loading}>
      <MaterialTable
        title={
          <div className="materialtable-title-container">
            <ExpandIcon style={{ color: colors.tensions }} />
            {t("tensionPage.title")}
          </div>
        }
        columns={columns}
        data={tensionList || []}
        components={{
          Actions: () => (
            <IconButton
              onClick={() => {
                setSelectedTension(undefined);
                setOpenDialog(true);
              }}
            >
              <AddIcon />
            </IconButton>
          ),
        }}
        onRowClick={(evt, selectedRow) => {
          setSelectedTension(selectedRow);
          setOpenDialog(true);
        }}
        localization={JSON.parse(JSON.stringify(t("dataTableProps")))}
        options={{
          draggable: false,
          paging: false,
          padding: "dense",
          headerStyle: {
            backgroundColor: getThemeColors(theme.palette.mode).background
              .paperLabel,
            height: "50px",
          },
        }}
      />
      <TensionDialogForm
        isOpen={openDialog}
        setClose={() => setOpenDialog(false)}
        tension={selectedTension}
        types={tensionTypesList}
        updateList={getTensions}
      />
    </IngBackdrop>
  );
}

export default TensionList;
