import React, { useState, useEffect, useContext, useCallback } from "react";
import { useParams } from "react-router-dom";
import AppContext from "../../../helpers/AppContext";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { UserService } from "../../../services";
import { useTranslation } from "react-i18next";
import IngBotonera from "../../molecules/IngBotonera";
import "./User.css";
import IngBackdrop from "../../atoms/IngBackdrop";
import { normalRequestBehavior, openAlert } from "../../../helpers/common";
import IngControlledTextField from "../../molecules/IngControlledTextField";
import IngControlledSelector from "../../molecules/IngControlledSelector";
import { Avatar, Divider } from "@mui/material";
import IngControlledCheckBox from "../../molecules/IngControlledCheckBox";
import { stringToColour } from "../../../helpers/Colors";
import ImagePicker from "../../organism/common/ImagePicker";

function UserForm() {
  const { setGlobal } = useContext(AppContext);
  const { t } = useTranslation();

  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);

  const [user, setUser] = useState(undefined);
  const isAdminApolo = user?.email === "apolo@nemus.es";

  const getUser = useCallback(() => {
    setLoading(true);
    UserService.get(id)
      .then(normalRequestBehavior)
      .then((data) => {
        setUser(data);
      })
      .catch((err) => {
        console.error(err);
        openAlert(setGlobal, t("userPage.alert.getError"), "error");
      })
      .finally(() => setLoading(false));
  }, [id, setGlobal, t]);

  const getRoles = useCallback(() => {
    UserService.getAllRoles()
      .then(normalRequestBehavior)
      .then((data) => setRoles(data.map((row) => row.name)))
      .catch((err) => {
        console.error(err);
        openAlert(setGlobal, t("userPage.alert.getRolesError"), "error");
      });
  }, [setGlobal, t]);

  useEffect(() => {
    getUser();
    getRoles();
    setGlobal((prev) => ({
      ...prev,
      pageTitle: t("userPage.title"),
    }));
  }, [getRoles, getUser, setGlobal, t]);

  const userSchema = yup.object({
    fullName: yup.string().required(t("userPage.helperTexts.name")),
    userName: yup.string().required(t("userPage.helperTexts.userName")),
    email: yup.string().email(),
    emailConfirmed: yup.boolean(),
    isDisabled: yup.boolean(),
    roles: yup.array().nullable(),
  });

  const {
    control,
    getValues,
    trigger,
    reset,
    formState: { isDirty, isValid, errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      userName: "",
      fullName: "",
      email: "",
      emailConfirmed: true,
      isDisabled: false,
      roles: [],
    },
    values: user,
    resolver: yupResolver(userSchema),
  });

  const handleSave = async () => {
    const flag = await trigger();

    if (flag) {
      setLoading(true);
      UserService.update(id, getValues())
        .then((res) => {
          if (res.ok) {
            res.json().then((data) => {
              setLoading(false);
              openAlert(setGlobal, t(`user.alert.updateSuccess`), "success");
              setUser((old) => ({ ...data, profileImage: old.profileImage }));
            });
          } else {
            res.json().then((data) => {
              let msg = t(`user.alert.error.updateError`);
              if (data.length > 0) {
                msg = data.map(
                  (row) => t(`user.alert.error.${row.code}`) + "\n"
                );
              }

              openAlert(setGlobal, msg, "error");
            });
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <div className="user-overflow-container">
      <IngBackdrop
        containerTag="Card"
        className="user-card-backdrop"
        loading={loading}
      >
        <div className="user-card-title">
          <div className="user-icon-container">
            <Avatar
              className="user-avatar"
              style={{
                backgroundColor: stringToColour(getValues("userName")),
              }}
              src={user?.profileImage?.base64}
            >
              {user?.fullName.split(" ").map((row) => row[0] || "U")}
            </Avatar>
            <div className="user-icon-text">
              {user?.fullName || t("fields.user.userName")}
            </div>
          </div>
        </div>
        <Divider />
        <div className="user-card-container">
          <div className="user-side-form">
            <IngControlledTextField
              control={control}
              name="userName"
              label={`${t("fields.user.userName")}:`}
              errors={errors.userName?.message.toString()}
            />
            <IngControlledTextField
              control={control}
              name="fullName"
              label={`${t("fields.user.fullName")}:`}
              errors={errors.fullName?.message.toString()}
            />
            <IngControlledTextField
              control={control}
              name={"email"}
              label={`${t("fields.user.email")}:`}
              readOnly={true}
            />
            <IngControlledSelector
              control={control}
              name="roles"
              multiple={true}
              options={roles}
              getOptionLabel={(row) => row}
              label={`${t("fields.user.roles")}:`}
              errors={errors.roles?.message.toString()}
              readOnly={isAdminApolo}
            />
            <div
              style={{ display: "flex", flexDirection: "row", gap: "inherit" }}
            >
              <IngControlledCheckBox
                control={control}
                name="isDisabled"
                label={t("fields.user.isDisabled")}
                errors={errors.isDisabled?.message.toString()}
                disabled={isAdminApolo}
              />
              <IngControlledCheckBox
                control={control}
                name="emailConfirmed"
                label={t("fields.user.emailConfirmed")}
                errors={errors.emailConfirmed?.message.toString()}
                disabled={true}
              />
            </div>
          </div>
          <ImagePicker id={user?.profileImageId} parentId={id} mode="user" />
        </div>
        <Divider />
        <div className="user-buttons">
          <IngBotonera
            onClickSave={handleSave}
            disableSave={!isDirty || !isValid}
            onClickCancel={() => reset()}
          />
        </div>
      </IngBackdrop>
    </div>
  );
}

export default UserForm;
