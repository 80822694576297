import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "../../../helpers/AppContext";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import ClearIcon from "@mui/icons-material/Clear";
import IngTextField from "../../atoms/IngTextField";
import IngButton from "../../atoms/IngButton";
import { TensionTypeService } from "../../../services";
import { normalRequestBehavior, openAlert } from "../../../helpers/common";
import SpainFlag from "../../atoms/SpainFlag";
import UnitedKingdomFlag from "../../atoms/UnitedKingdomFlag";

function TensionTypeDialogForm({ isOpen, setClose, tensionType, updateList }) {
  const { t } = useTranslation();
  const { setGlobal } = useContext(AppContext);

  const [esName, setEsName] = useState(undefined);
  const [enName, setEnName] = useState(undefined);
  const [code, setCode] = useState(undefined);

  useEffect(() => {
    if (tensionType) {
      setEsName(tensionType.esName);
      setEnName(tensionType.enName);
      setCode(tensionType.code);
    }
  }, [tensionType]);

  const handleClose = () => {
    setClose();
    setEsName(undefined);
    setEnName(undefined);
    setCode(undefined);
  };

  const handleSave = async () => {
    let requestTensionType = {
      esName,
      enName,
      code,
    };

    TensionTypeService.createOrUpdate(requestTensionType, tensionType?.id)
      .then(normalRequestBehavior)
      .then((data) => {
        if (typeof data === "string" && data.includes("dynamic.customErrors")) {
          openAlert(setGlobal, t(data), "error");
        } else {
          updateList();
          openAlert(
            setGlobal,
            tensionType?.id
              ? t("tensionPage.type.alert.updateSuccess")
              : t("tensionPage.type.alert.createSuccess"),
            "success"
          );
          handleClose();
        }
      })
      .catch((err) => {
        console.error(err);
        openAlert(setGlobal, t("tensionPage.type.alert.createError"), "error");
      });
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        {tensionType?.id
          ? t("tensionPage.type.update")
          : t("tensionPage.type.create")}
      </DialogTitle>
      <DialogContent className="common-createDialog-container">
        <IngTextField
          label={`${t("fields.tensionType.code")}:`}
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />
        <IngTextField
          label={
            <>
              {`${t("common.fields.name")}:`}
              <SpainFlag size="small" mode="label" />
            </>
          }
          value={esName}
          onChange={(e) => setEsName(e.target.value)}
        />
        <IngTextField
          label={
            <>
              {`${t("common.fields.name")}:`}
              <UnitedKingdomFlag size="small" mode="label" />
            </>
          }
          value={enName}
          onChange={(e) => setEnName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <IngButton
          onClick={handleSave}
          disabled={
            !esName ||
            esName.trim() === "" ||
            !enName ||
            enName.trim() === "" ||
            !code ||
            code.trim() === ""
          }
          label={t("common.confirm")}
          icon={<PanoramaFishEyeIcon />}
          color="success"
        />
        <IngButton
          onClick={() => handleClose()}
          label={t("common.cancel")}
          icon={<ClearIcon />}
          color="error"
        />
      </DialogActions>
    </Dialog>
  );
}

export default TensionTypeDialogForm;
