export default class Auth {
  
  static getToken() {
    const token = localStorage.getItem("token");
    return token;
  }

  static getUser() {
    const token = this.getToken();

    if (token) {
      const json = JSON.parse(token);
      if (json) {
        return json.user;
      }
    }
    return undefined;
  }

  static getRoles(){
    const token = this.getToken();

    if (token) {
      const json = JSON.parse(token);
      if (json) {
        return json.roles;
      }
    }
    return undefined;
  }

  static isLogged() {
    const token = this.getToken();
    return token ? true : false;
  }

  static logout() {
    localStorage.removeItem("token");
    window.location.replace("/");
  }
}
