import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";
import { colors } from "../../helpers/Colors";
import CloudIcon from "@mui/icons-material/Cloud";

/**
 * Backdrop parcial, debe utilizarse como una etiqueta contenedora, es recomendarble pasarle un className, por defecto utiliza un div, de forma irremediable siempre tendra un minHeigth de "200" y posicion "relative" en caso de contener una grid, en el "className se le debe especificar tanto el "grid-template-columns" como el "grid-template-rows".
 * @Component
 * @param {Object} props Parametros de la clase
 * @param {any} [props.icon] Etiqueta usada para crear el contenedor, ya sea un div de HTML o una Card de Material UI
 * @param {string} [props.text] Texto personalizado en el lugar de "Cargando..."
 */
function IngNoData(props) {
  const theme = useTheme();
  const { t } = useTranslation();

  const { text, icon } = props;

  return (
    <div className="ingnodata-container">
      <div
        className="ingnodata-icon"
        style={{
          backgroundColor: theme.palette.info.main,
          color: colors.whitesmoke,
        }}
      >
        {icon || <CloudIcon />}
      </div>
      <div className="ingbackdrop-ingnodata-text">
        {text || t("common.noData")}
      </div>
    </div>
  );
}

export default IngNoData;
