import React, { useCallback, useContext, useEffect, useState } from "react";
import AppContext from "../../../helpers/AppContext";
import { useTranslation } from "react-i18next";
import {
  normalRequestBehavior,
  openAlert,
  openConfirmationDialog,
} from "../../../helpers/common";
import IngBackdrop from "../../atoms/IngBackdrop";
import MaterialTable from "@material-table/core";
import { IconButton, useTheme } from "@mui/material";
import { colors, getThemeColors } from "../../../helpers/Colors";
import { TensionTypeService } from "../../../services";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import UnitedKingdomFlag from "../../atoms/UnitedKingdomFlag";
import SpainFlag from "../../atoms/SpainFlag";
import TensionTypeDialogForm from "./TensionTypeDialogForm";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";

function TensionTypeList() {
  const { setGlobal } = useContext(AppContext);
  const { t } = useTranslation();
  const theme = useTheme();

  const [loading, setLoading] = useState(false);
  const [tensionTypeList, setTensionTypeList] = useState(undefined);
  const [selectedTensionType, setSelectedTensionType] = useState(undefined);
  const [openDialog, setOpenDialog] = useState(false);

  const getTensionTypes = useCallback(() => {
    setLoading(true);
    TensionTypeService.getAll()
      .then(normalRequestBehavior)
      .then((data) => setTensionTypeList(data))
      .catch((err) => {
        openAlert(setGlobal, t("tensionPage.type.alert.getAllError"), "error");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [setGlobal, t]);

  useEffect(() => {
    getTensionTypes();
  }, [getTensionTypes]);

  const handleRemove = async (id) => {
    openConfirmationDialog({
      setGlobal,
      title: t("tensionPage.type.removeDialog.title"),
      text: t("tensionPage.type.removeDialog.text"),
      onConfirm: () => {
        setLoading(true);
        TensionTypeService.remove(id)
          .then(normalRequestBehavior)
          .then(() => {
            getTensionTypes();
            openAlert(
              setGlobal,
              t("tensionPage.type.alert.removeSuccess"),
              "success"
            );
          })
          .catch((err) => {
            openAlert(
              setGlobal,
              t("tensionPage.type.alert.removeError"),
              "error"
            );
            console.error(err);
          })
          .finally(() => setLoading(false));
      },
    });
  };

  const columns = [
    {
      title: t("fields.tensionType.code"),
      field: "code",
    },
    {
      title: (
        <>
          {`${t("common.fields.name")}ㅤ`}
          <SpainFlag size="small" />
        </>
      ),
      field: `esName`,
    },
    {
      title: (
        <>
          {`${t("common.fields.name")}ㅤ`}
          <UnitedKingdomFlag size="small" />
        </>
      ),
      field: `enName`,
    },
    {
      title: t("common.fields.createdBy"),
      field: "createdBy.email",
    },
    {
      title: t("common.fields.creationDate"),
      field: "creationDate",
      render: (row) => moment(row.creationDate).format("DD/MM/YYYY"),
    },
    {
      width: "40px",
      render: (row) =>
        row.id !== 1 && (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleRemove(row?.id);
            }}
          >
            <DeleteIcon />
          </IconButton>
        ),
    },
  ];

  return (
    <IngBackdrop loading={loading}>
      <MaterialTable
        title={
          <div className="materialtable-title-container">
            <UnfoldMoreIcon style={{ color: colors.tensions }} />
            {t("tensionPage.type.title")}
          </div>
        }
        columns={columns}
        data={tensionTypeList || []}
        components={{
          Actions: () => (
            <IconButton
              onClick={() => {
                setSelectedTensionType(undefined);
                setOpenDialog(true);
              }}
            >
              <AddIcon />
            </IconButton>
          ),
        }}
        onRowClick={(evt, selectedRow) => {
          setSelectedTensionType(selectedRow);
          setOpenDialog(true);
        }}
        localization={JSON.parse(JSON.stringify(t("dataTableProps")))}
        options={{
          draggable: false,
          paging: false,
          padding: "dense",
          headerStyle: {
            backgroundColor: getThemeColors(theme.palette.mode).background
              .paperLabel,
            height: "50px",
          },
        }}
      />
      <TensionTypeDialogForm
        isOpen={openDialog}
        setClose={() => setOpenDialog(false)}
        tensionType={selectedTensionType}
        updateList={getTensionTypes}
      />
    </IngBackdrop>
  );
}

export default TensionTypeList;
