import React from "react";
import IngButton from "../atoms/IngButton";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import { useTranslation } from "react-i18next";

/**
 * Ing Botonera personalizada
 * @Component
 * @param {Object} props – parametros de la clase
 * @param {string} [props.className] - Custom className, se suma al className existente
 * @param {Object} [props.style] - Custom className, se suma al className existente
 * @param {React.MouseEventHandler<HTMLButtonElement>} props.onClickSave - Accion de guardar
 * @param {boolean} [props.disableSave] - Deshabilitar boton de guardar
 * @param {React.MouseEventHandler<HTMLButtonElement>} props.onClickCancel - Accion de Cancelar
 * @param {React.MouseEventHandler<HTMLButtonElement> | undefined} [props.onClickDelete] - Accion de borrar
 */
function IngBotonera(props) {
  const { t } = useTranslation();

  const { onClickSave, onClickCancel, onClickDelete } = props;

  return (
    <div
      className={`${props.className} ingbotonera-container`}
      style={props.style}
    >
      <IngButton
        variant={"text"}
        color="inherit"
        label={t("common.cancel")}
        icon={<ClearIcon />}
        onClick={onClickCancel}
      />
      {onClickDelete && (
        <IngButton
          variant="contained"
          color="error"
          label={t("common.remove")}
          icon={<DeleteIcon />}
          onClick={onClickDelete}
        />
      )}
      <IngButton
        label={t("common.save")}
        color="success"
        disabled={props.disableSave}
        icon={<SaveIcon />}
        onClick={onClickSave}
      />
    </div>
  );
}

export default IngBotonera;
