import React, { useContext, useEffect, useRef } from "react";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import AppContext from "../../helpers/AppContext";

const defaultValues = { msg: "", open: false, severity: "info" };

function IngAlert() {
  const { global, setGlobal } = useContext(AppContext);

  useEffect(() => {
    setGlobal((old) => ({
      ...old,
      alert: defaultValues,
    }));
  }, [setGlobal]);

  const alertRef = useRef(null);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setGlobal((old) => ({
      ...old,
      alert: defaultValues,
    }));
  };

  if (!global?.alert?.open) {
    return <></>;
  }

  return (
    <Snackbar
      className="snackbar"
      open={global?.alert?.open}
      autoHideDuration={4000}
      onClose={handleClose}
    >
      <MuiAlert
        elevation={6}
        ref={alertRef}
        variant="filled"
        onClose={handleClose}
        severity={global?.alert?.severity}
      >
        {global?.alert?.msg}
      </MuiAlert>
    </Snackbar>
  );
}

export default IngAlert;
