import * as React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";

/**
 * Ing Buscador personalizado
 * @Component
 * @param {Object} props – parametros de la clase
 * @param {React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>} props.submit - Función onSubmit del buscador
 */
function IngBuscador(props) {
  const { t } = useTranslation();

  return (
    <Paper
      component="form"
      sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 400 }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={t("common.search")}
        inputProps={{ "aria-label": "search google maps" }}
        onChange={props.submit}
        onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
      />
      <SearchIcon />
    </Paper>
  );
}

export default IngBuscador;
