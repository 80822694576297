import React, {
  useRef,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import AppContext from "../../../helpers/AppContext";
import { ImageService } from "../../../services";
import {
  acceptedImgExtensions,
  normalRequestBehavior,
  openAlert,
  openConfirmationDialog,
} from "../../../helpers/common";
import { useTranslation } from "react-i18next";
import {
  CropFreeSharp,
  Delete,
  InfoOutlined,
  Publish,
} from "@mui/icons-material";
import { IconButton, InputLabel, Tooltip } from "@mui/material";

/**
 * Ing Textfield personalizado
 * @Component
 * @param {Object} props – parametros de la clase
 * @param {String} [props.id] - Id de la imagen en caso de que exista
 * @param {String | number} props.parentId - Id del objeto que posee la imagen
 * @param {"exercise" | "user"} props.mode - Objeto relacionado con la imagen (se utiliza el string para buscar el endpoint)
 */
function ImagePicker({ id, parentId, mode }) {
  const { setGlobal } = useContext(AppContext);
  const { t } = useTranslation();

  const [image, setImage] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const imageInputRef = useRef(null);

  const getImage = useCallback(() => {
    if (id) {
      setLoading(true);
      ImageService.get(id)
        .then(normalRequestBehavior)
        .then((data) => setImage(data))
        .catch((err) => {
          console.error(err);
          openAlert(setGlobal, t("image.alert.getError"), "error");
        })
        .finally(() => setLoading(false));
    }
  }, [id, setGlobal, t]);

  useEffect(() => {
    getImage();
  }, [getImage]);

  const createImage = async () => {
    const file = imageInputRef.current.files[0];

    if (file) {
      setLoading(true);
      const extension = file.name.split(".").pop();
      if (!acceptedImgExtensions[mode].includes(extension)) {
        openAlert(setGlobal, t(`image.alert.formatError.${mode}`), "error");
        setLoading(false);
        return;
      }

      setLoading(true);

      let formData = new FormData();
      formData.append("file", file);

      ImageService.createBy(mode, parentId, formData)
        .then(normalRequestBehavior)
        .then((data) => {
          openAlert(setGlobal, t("image.alert.createSuccess"), "success");
          setImage(data);
        })
        .catch((err) => {
          console.error(err);
          openAlert(setGlobal, t("image.alert.createError"), "error");
        })
        .finally(() => {
          imageInputRef.current.value = "";
          setLoading(false);
        });
    }
  };

  const removeImage = () => {
    openConfirmationDialog({
      setGlobal,
      title: t("image.removeDialog.title"),
      text: t("image.removeDialog.text"),
      onConfirm: () => {
        setLoading(true);
        ImageService.remove(image.id)
          .then(normalRequestBehavior)
          .then((data) => {
            if (data === true) {
              setImage(undefined);
              openAlert(setGlobal, t("image.alert.removeSuccess"), "success");
            } else {
              throw new Error("Failed successfully");
            }
          })
          .catch((err) => {
            console.error(err);
            openAlert(setGlobal, t("image.alert.removeError"), "error");
          })
          .finally(() => {
            setLoading(false);
          });
      },
    });
  };

  return (
    <>
      <div className="exercise-image-input-container">
        <div className="exercise-image-input-label">
          <>
            <InputLabel>{`${t("image.title")}:`}</InputLabel>
            <Tooltip title={t(`image.validFormat.${mode}`)}>
              <InfoOutlined color="info" style={{ fontSize: 16 }} />
            </Tooltip>
          </>
        </div>
        {image ? (
          <img
            className="exercise-image-input-img"
            alt={t("image.title")}
            src={image.base64}
          />
        ) : (
          <div className="exercise-image-input-img">
            <CropFreeSharp style={{ fontSize: 220 }} />
          </div>
        )}
        <div className="exercise-image-input-buttons">
          <input
            type="file"
            id="image_input"
            accept={`.${acceptedImgExtensions[mode].join(",.")}`}
            ref={imageInputRef}
            onChange={createImage}
            hidden
          />
          <Tooltip title={t("image.addHelp")}>
            <span>
              <IconButton
                disabled={loading}
                onClick={() => imageInputRef.current.click()}
              >
                <Publish />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title={t("image.deleteHelp")}>
            <span>
              <IconButton disabled={!image || loading} onClick={removeImage}>
                <Delete color="error" />
              </IconButton>
            </span>
          </Tooltip>
        </div>
      </div>
    </>
  );
}

export default ImagePicker;
