import React, { useState } from "react";
import { InputAdornment, TextField, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTranslation } from "react-i18next";

/**
 * Ing Textfield personalizado
 * @Component
 * @param {Object} props – parametros de la clase
 * @param {String} [props.className] - estilo personalizado del TextField
 * @param {String} [props.name] - Nombre del texfield
 * @param {any} [props.variant] - variant del TextField
 * @param {React.ReactNode} [props.label] - título del textfield
 * @param {unknown} [props.value] - value del TextField
 * @param {any} [props.color] - color del TextField
 * @param {React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>} [props.onChange] - función onChange del TextField
 * @param {boolean} [props.disabled] - Flag para deshabilitar el TextField
 * @param {boolean} [props.readOnly] - Flag para que el TextField sea solo de lectura
 * @param {String} [props.placeholder] - placeholder del Textfield
 * @param {String} [props.errors] - texto de error para los validadores
 * @param {boolean} [props.multiline] - controla si el textfield es expandible en función del contenido
 * @param {React.KeyboardEventHandler<HTMLDivElement>} [props.onKeyPress] - función para enter
 * @param {number} [props.rows] - Numero de lineas que debe ocupar el textfield multilinea
 * @param {boolean} [props.notFullWidth] - Evita que el TextField sea fullWidth
 * @param {boolean} [props.isPassword] - Flag para saber si el input es para un password
 * @param {any} [props.onKeyDown] - onKeyDown del TextField
 * @param {any} [props.onBlur] - onBlur del TextField
 * @param {"text" | "email" | "number" | "tel" | "text" | "url"} [props.type] - Type para el input
 * @param {any} [props.endAdornment] - Adorno al final del TextField OJO: Sobreescribe el ReadOnly Adornment
 */

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiFormHelperText-root": {
    color: theme.palette.error.main,
  },
}));

function IngTextField(props) {
  const [isSelected, setIsSelected] = useState(false);
  const { t } = useTranslation();

  return (
    <CustomTextField
      className={props.className}
      name={props.name}
      label={props.label}
      variant={props.variant || "standard"}
      value={props.value || props.value === 0 ? props.value : ""}
      color={props.color || (props.readOnly && "info") || "text"}
      onChange={props.onChange}
      InputProps={{
        readOnly: props.readOnly ? true : false,
        endAdornment: props.endAdornment ? (
          <InputAdornment position="end">{props.endAdornment}</InputAdornment>
        ) : props.readOnly ? (
          <InputAdornment position="end">
            <Tooltip title={t("common.readOnly")}>
              <InfoOutlinedIcon color={"info"} />
            </Tooltip>
          </InputAdornment>
        ) : (
          <></>
        ),
      }}
      disabled={props.disabled}
      placeholder={props.placeholder}
      rows={props.rows}
      multiline={props.multiline}
      fullWidth={!props.notFullWidth}
      onKeyPress={props.onKeyPress}
      helperText={props.errors}
      InputLabelProps={{
        shrink:
          ((props.value || props.value === 0) && props.value !== "") ||
          props.rows ||
          props.readOnly ||
          isSelected
            ? true
            : false,
      }}
      type={props.isPassword ? "password" : props.type || "text"}
      onKeyDown={props.onKeyDown}
      onBlur={() => {
        props.onBlur && props.onBlur();
        setIsSelected(false);
      }}
      onFocus={() => setIsSelected(true)}
    />
  );
}

export default IngTextField;
