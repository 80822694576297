function getAll() {
  const token = localStorage.getItem("token");
  let accessToken = JSON.parse(token);
  return fetch("/api/user", {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken["token"]}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

function getFiltered({ limit, offset, search = "" }) {
  const token = localStorage.getItem("token");
  let accessToken = JSON.parse(token);

  return fetch(`/api/user/list/filtered`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken["token"]}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      limit,
      offset,
      search,
    }),
  });
}

function getAllRoles() {
  const token = localStorage.getItem("token");
  let accessToken = JSON.parse(token);
  return fetch("/api/user/roles", {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken["token"]}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

function get(id) {
  const token = localStorage.getItem("token");
  let accessToken = JSON.parse(token);
  return fetch("/api/user/" + id, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken["token"]}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

function update(id, datos) {
  const token = localStorage.getItem("token");
  let accessToken = JSON.parse(token);
  return fetch("/api/user/" + id, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${accessToken["token"]}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(datos),
  });
}

export { getAll, getFiltered, getAllRoles, get, update };
