import { roles } from "../helpers/common";

async function login(email, password) {
  const datos = { Email: email, Password: password };

  return fetch("/connect", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(datos),
  });
}

function logOut() {
  localStorage.removeItem("token");
}

function getUserInfo() {
  return JSON.parse(localStorage.getItem("token"));
}

function isAuthenticated() {
  const token = localStorage.getItem("token");
  if (token === null) return false;

  const tokenInfo = JSON.parse(token);
  if (tokenInfo.expireDate === undefined) return true;
  else return new Date(tokenInfo.expireDate) >= new Date();
}

function isInRole(role) {
  const token = localStorage.getItem("token");
  if (token === null) return false;
  if (JSON.parse(token).roles.includes(roles.keyuser)) {
    return true;
  } else {
    return JSON.parse(token).roles.includes(role);
  }
}

function isInSomeRole(roles) {
  const token = localStorage.getItem("token");
  if (token === null) return false;
  if (JSON.parse(token).roles.includes(roles.keyuser)) {
    return true;
  } else {
    return JSON.parse(token).roles.some((r) => roles.includes(r));
  }
}

async function verification(token, email) {
  const datos = { Token: token, Email: email };
  return fetch("/connect/verification", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(datos),
  });
}

async function resetPassword(token, email, pass) {
  const datos = { Token: token, Email: email, NewPassword: pass };
  return fetch("/connect/confirmResetPassword", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(datos),
  });
}

function isKeyuser() {
  return isInRole(roles.keyuser);
}

export {
  login,
  logOut,
  getUserInfo,
  isAuthenticated,
  isInRole,
  isInSomeRole,
  verification,
  resetPassword,
  isKeyuser,
};
