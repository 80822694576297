import React, { useCallback, useEffect } from "react";
import "./ResetPassword.css";
import IngButton from "../../atoms/IngButton";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthService } from "../../../services";
import { baseUrl } from "../../../helpers/common";
import MuiAlert from "@mui/material/Alert";
import {
  CircularProgress,
  Paper,
  Snackbar,
  ThemeProvider,
} from "@mui/material";
import { colors, getTheme } from "../../../helpers/Colors";
import packageInfo from "../../../../package.json";
import { useSearchParams } from "react-router-dom";
import LockResetIcon from "@mui/icons-material/LockReset";
import IngTextField from "../../atoms/IngTextField";
import OfflineShareIcon from "@mui/icons-material/OfflineShare";

function ResetPassword() {
  //Borrar Token para renovarlo
  localStorage.removeItem("token");
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const email = searchParams.get("email");
  const userName = searchParams.get("userPagePageName");
  const [loading, setLoading] = useState(false);
  const [correct, setCorrect] = useState(false);
  const [pass1, setPass1] = useState("");
  const [pass2, setPass2] = useState("");
  const [pass1TextError, setPass1TextError] = useState("");
  const [errorText, setErrorText] = useState("");

  //Para los alerts
  const [open, setOpen] = React.useState(false);
  const Alert = React.forwardRef(function Alert(props, ref) {
    return (
      <MuiAlert
        elevation={6}
        ref={ref}
        variant="filled"
        {...props}
        style={{ color: colors.whitesmoke }}
      />
    );
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  //Restablecer la contraseña
  const resetPassword = useCallback(
    (token, email, pass1) => {
      setLoading(true);
      AuthService.resetPassword(token, email, pass1)
        .then(async (response) => {
          if (response.status === 200) {
            setCorrect(true);
          } else {
            throw new Error(response.statusText);
          }
        })
        .catch((err) => {
          console.error(err);
          setCorrect(false);
          setErrorText(t("resetPassword.errorReset"));
          setOpen(true);
        })
        .finally(() => setLoading(false));
    },
    [t]
  );

  //Validar la contraseña
  const passWordValidation = (pass, pass2) => {
    if (!pass || !pass2) {
      setErrorText(t("userPagePage.helperTexts.password"));
      setPass1TextError(t("userPagePage.helperTexts.confirmPassword"));
      setOpen(true);
      return false;
    }
    var correctPass = true;
    var textError = t("userPagePage.helperTexts.passInfo") + "\n";
    var extraText = "";
    var nExtraRules = 3;
    //longitud minima de 6 caracteres
    if (pass.length < 6) {
      textError += t("userPagePage.helperTexts.passLength") + "\n";
      correctPass = false;
    }
    //longitud maxima de 100 caracteres
    if (pass.length > 100) {
      textError += t("userPagePage.helperTexts.passMaxLength") + "\n";
      correctPass = false;
    }
    //Que no use el nombre de usuario
    if (userName) {
      if (pass.includes(userName)) {
        textError += t("userPagePage.helperTexts.passUserName") + "\n";
        correctPass = false;
      }
    }
    //Las contraseñas deben coincidir
    if (pass !== pass2) {
      textError += t("userPagePage.helperTexts.confirmPassword") + "\n";
      correctPass = false;
    }

    //Comprobaciones extra
    //Al menos una mayuscula
    if (!/[A-Z]/.test(pass)) {
      nExtraRules--;
      extraText += " -" + t("userPagePage.helperTexts.passUpper") + "\n";
      correctPass = false;
    }
    //Al menos una minuscula
    if (!/[a-z]/.test(pass)) {
      nExtraRules--;
      extraText += " -" + t("userPagePage.helperTexts.passLower") + "\n";
      correctPass = false;
    }
    //Al monos un numero
    if (!/[0-9]/.test(pass)) {
      nExtraRules--;
      extraText += " -" + t("userPagePage.helperTexts.passLower") + "\n";
      correctPass = false;
    }
    //Caracter especial
    if (!/[~!@#$%^&*_\-+=`\\|()[\]:;"'<>,.?/]/.test(pass)) {
      nExtraRules--;
      extraText += " -" + t("userPagePage.helperTexts.passSpecial") + "\n";
      correctPass = false;
    }

    //Identity necesita al menos 3 comprobaciones extra para la pass
    if (nExtraRules <= 0) {
      textError += extraText;
      correctPass = false;
    }

    if (correctPass) {
      setPass1TextError("");
      return true;
    } else {
      setErrorText(textError);
      setPass1TextError(t("userPagePage.helperTexts.passSecurity"));
      setOpen(true);
      return false;
    }
  };

  return (
    <ThemeProvider theme={getTheme(localStorage.getItem("theme"))}>
      <div
        className="resetPass-background"
        style={{
          background: `url('${baseUrl}/img/login-background.jpg')`,
          backgroundSize: "cover",
        }}
      >
        <Paper className="resetPass-card">
          {!token || !email ? (
            <>{t("resetPassword.notValid")}</>
          ) : !correct ? (
            <>
              <div className="resetPass-title">
                {correct
                  ? t("resetPassword.titleConfirm")
                  : t("resetPassword.title")}
              </div>
              <div>{t("resetPassword.info")}</div>
              <IngTextField
                color={"primary"}
                label={t("resetPassword.passinput1")}
                variant="filled"
                onChange={(t) => setPass1(t.target.value)}
                value={pass1}
                isPassword
                name={"password"}
                errors={pass1TextError}
              />

              <IngTextField
                color={"primary"}
                label={t("resetPassword.passinput2")}
                variant="filled"
                onChange={(t) => setPass2(t.target.value)}
                value={pass2}
                isPassword
                name={"confirmPassword"}
              />

              <IngButton
                className="resetPass-button"
                onClick={() => {
                  if (passWordValidation(pass1, pass2)) {
                    resetPassword(token, email, pass1);
                  }
                }}
                variant="contained"
                label={
                  loading
                    ? t("resetPassword.loadingResetButton")
                    : t("resetPassword.confirmResetButton")
                }
                icon={
                  loading ? (
                    <CircularProgress
                      color="inherit"
                      className="resetPass-loading"
                      size={20}
                    />
                  ) : (
                    <LockResetIcon className="resetPass-iconButton" />
                  )
                }
              />
            </>
          ) : (
            <>
              <div className="resetPass-title">{t("resetPassword.title")}</div>
              <div>{t("resetPassword.correctReset")}</div>

              <IngButton
                className="verification-button"
                onClick={() => {
                  const appLink = "nemus://open";
                  //const fallbackLink = 'https://play.google.com/store/apps/details?id=com.example.nemus';

                  // Intenta abrir la aplicación
                  window.location.href = appLink;

                  // Si la aplicación no está instalada, espera un breve momento y redirige a la tienda de aplicaciones
                  // setTimeout(() => {
                  //   window.location.href = fallbackLink;
                  // }, 1000); // Espera 1 segundo antes de redirigir a la tienda de aplicaciones
                }}
                variant="contained"
                label={t("verification.appButton")}
                icon={<OfflineShareIcon className="verification-iconButton" />}
              />
            </>
          )}
        </Paper>
        <div
          className="resetPass-version-number"
          style={{
            backgroundColor: colors.apolo,
            color: colors.whitesmoke,
          }}
        >
          v{packageInfo.version}
        </div>
      </div>

      <Snackbar
        className="snackbar"
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        {/*@ts-ignore*/}
        <Alert onClose={handleClose} severity="error">
          {errorText.split("\n").map((line, index) => (
            <span key={index}>
              {line}
              <br />
            </span>
          ))}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
}

export default ResetPassword;
