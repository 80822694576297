import React, { useCallback, useContext, useEffect, useState } from "react";
import AppContext from "../../../helpers/AppContext";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ExerciseService, MuscleService } from "../../../services";
import { normalRequestBehavior, openAlert } from "../../../helpers/common";
import IngBackdrop from "../../atoms/IngBackdrop";
import ExerciseForm from "../../organism/exercise/ExerciseForm";
import ImagePicker from "../../organism/common/ImagePicker";

function ExerciseDetail() {
  const { setGlobal } = useContext(AppContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [exercise, setExercise] = useState(undefined);
  const [muscles, setMuscles] = useState([]);

  const getExercise = useCallback(() => {
    setLoading(true);
    ExerciseService.get(id)
      .then((res) => normalRequestBehavior(res, true))
      .then((data) => {
        setExercise(data);
      })
      .catch((err) => {
        console.error(err);
        openAlert(setGlobal, t("exercisePage.alert.getError"), "error");
      })
      .finally(() => setLoading(false));
  }, [id, setGlobal, t]);

  const getMuscles = useCallback(() => {
    setLoading(true);
    MuscleService.getSelector()
      .then(normalRequestBehavior)
      .then((data) => setMuscles(data))
      .catch((err) => {
        console.error(err);
        openAlert(setGlobal, t("musclePage.alert.getAllError"), "error");
      })
      .finally(() => setLoading(false));
  }, [setGlobal, t]);

  useEffect(() => {
    getMuscles();
    getExercise();
    setGlobal((prev) => ({
      ...prev,
      pageTitle: t("exercisePage.titleDetail"),
    }));
  }, [getExercise, getMuscles, setGlobal, t]);

  const handleUpdateExercise = (exercise) => {
    setLoading(true);

    ExerciseService.update(exercise.id, exercise)
      .then(normalRequestBehavior)
      .then((data) => {
        if (typeof data === "string" && data.includes("dynamic.customErrors"))
          openAlert(setGlobal, t(data), "error");
        else {
          setExercise(data);
          openAlert(
            setGlobal,
            t("exercisePage.alert.updateSuccess"),
            "success"
          );
        }
      })
      .catch((err) => {
        console.error(err);
        openAlert(setGlobal, t("exercisePage.alert.updateError"), "error");
      })
      .finally(() => setLoading(false));
  };

  const handleRemoveExercise = () => {
    ExerciseService.remove(id)
      .then(normalRequestBehavior)
      .then((data) => {
        if (data === true) {
          navigate(-1);
          openAlert(
            setGlobal,
            t("exercisePage.alert.removeSuccess"),
            "success"
          );
        } else {
          throw new Error("Failed successfully");
        }
      })
      .catch((err) => {
        console.error(err);
        openAlert(setGlobal, t("exercisePage.alert.removeError"), "error");
      });
  };

  return (
    <IngBackdrop className="exercise-detail-main-container" loading={loading}>
      <div className="exercise-detail-left-card">
        <ExerciseForm
          exercise={exercise}
          muscles={muscles}
          update={handleUpdateExercise}
          remove={handleRemoveExercise}
        />
      </div>
      <div className="exercise-detail-right-card">
        <ImagePicker id={exercise?.imageId} parentId={id} mode="exercise" />
      </div>
    </IngBackdrop>
  );
}

export default ExerciseDetail;
