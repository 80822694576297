import React from "react";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

/**
 * @Component
 * @param {Object} props – parametros de la clase
 * @param {String} props.versionNum - Número de version
 * @param {string | "prod" | "test"} [props.type] - Tipo de version
 * @param {string} [props.title] - Titulo mostrado en la chip
 * @param {Array} props.changes - Lista de cambios
 */
function IngVersion({ versionNum, type, title, changes }) {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <>
      <h3 className="about-version-title">
        {versionNum}
        <b
          className="about-chip"
          style={
            type === "prod"
              ? {
                  color: theme.palette.success.contrastText,
                  backgroundColor: theme.palette.success.main,
                }
              : {
                  color: theme.palette.info.contrastText,
                  backgroundColor: theme.palette.info.main,
                }
          }
        >
          {title
            ? title
            : type === "prod"
            ? t("aboutPage.news.prod")
            : t("aboutPage.news.test")}
        </b>
      </h3>
      <ul>
        {changes.map((row) => (
          <li key={row}>{row}</li>
        ))}
      </ul>
    </>
  );
}

export default IngVersion;
