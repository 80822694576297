import React, { useContext, useEffect } from "react";
import "./About.css";
import AppContext from "../../../helpers/AppContext";
import { useTranslation } from "react-i18next";
import { colors } from "../../../helpers/Colors";
import { baseUrl } from "../../../helpers/common";
import { Divider, useTheme } from "@mui/material";
import packageInfo from "../../../../package.json";
import IngVersion from "../../atoms/IngVersion";

function About() {
  const { setGlobal } = useContext(AppContext);
  const { t } = useTranslation();

  const theme = useTheme();

  useEffect(() => {
    setGlobal((prev) => ({ ...prev, pageTitle: t("aboutPage.title") }));
  }, [setGlobal, t]);

  const versions = [
    {
      versionNum: "0.0.1",
      type: "test",
      title: t("aboutPage.news.0.0.1.title"),
      changes: [
        t("aboutPage.news.0.0.1.users"),
        t("aboutPage.news.0.0.1.exercises"),
        t("aboutPage.news.0.0.1.muscles"),
        t("aboutPage.news.0.0.1.tensions"),
      ],
    },
  ];

  return (
    <div
      className="about-container"
      style={{
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <div className="about-header-container">
        <div
          className="about-logo-container"
          style={{ backgroundColor: colors.ingesis }}
        >
          <img
            className="about-logo"
            src={baseUrl + "/img/ingesis-logo.png"}
            alt="about-logo"
          />
        </div>
        <div className="about-header-text-container">
          <div>
            <b className="about-header-title">Apolo Web</b> v
            {packageInfo.version}
          </div>
          Powered by Ingesis Automatización S.L.
        </div>
      </div>
      <Divider />
      <div className="about-news-container">
        {versions.map((row) => (
          <IngVersion
            key={row.versionNum}
            versionNum={row.versionNum}
            type={row.type}
            title={row.title || undefined}
            changes={row.changes}
          />
        ))}
      </div>
    </div>
  );
}

export default About;
