import React from "react";
import { useTranslation } from "react-i18next";
import { Card, useTheme } from "@mui/material";
import { colors } from "../../helpers/Colors";

/**
 * Backdrop parcial, debe utilizarse como una etiqueta contenedora, es recomendarble pasarle un className, por defecto utiliza un div, de forma irremediable siempre tendra un minHeigth de "200" y posicion "relative" en caso de contener una grid, en el "className se le debe especificar tanto el "grid-template-columns" como el "grid-template-rows".
 * @Component
 * @param {Object} props Parametros de la clase
 * @param {"div" | "Card"} [props.containerTag] Etiqueta usada para crear el contenedor, ya sea un div de HTML o una Card de Material UI
 * @param {string} [props.className] Estilos del contenedor
 * @param {string} [props.text] Texto personalizado en el lugar de "Cargando..."
 * @param {boolean} props.loading Flag para saber si esta cargando o ya ha terminado
 * @param {boolean} [props.hidden] Ocultar la tag y sus hijos
 * @param {any} [props.children] Hijos del componente
 */
function IngBackdrop(props) {
  const theme = useTheme();
  const { t } = useTranslation();

  const { containerTag, className, loading, text, children } = props;

  const containerClassName = `${
    className && className + " "
  }ingbackdrop-container`;

  const content = (
    <>
      {loading && (
        <div className="ingbackdrop-loading-container">
          <div
            className="ingbackdrop-icon"
            style={{
              backgroundColor: theme.palette.primary.main,
              color: colors.whitesmoke,
            }}
          >
            <div className="ingbackdrop-loader"></div>
          </div>
          <div className="ingbackdrop-ingnodata-text">
            {text || t("common.loading")}
          </div>
        </div>
      )}
      <div
        hidden={!loading}
        className="ingbackdrop-curtain"
        style={{
          backgroundColor: theme.palette.background.default,
        }}
      />
      {children}
    </>
  );

  let response;

  switch (containerTag) {
    case "Card":
      response = (
        <Card className={containerClassName} hidden={props.hidden}>
          {content}
        </Card>
      );
      break;
    default:
      response = (
        <div className={containerClassName} hidden={props.hidden}>
          {content}
        </div>
      );
  }

  return response;
}

export default IngBackdrop;
