import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { styled } from "@mui/material";
import IngTextField from "./IngTextField";

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiFormHelperText-root": {
    color: theme.palette.error.main,
  },
}));

/**
 * Ing Selector Básico personalizado
 * @Component
 * @param {Object} props – parametros de la clase
 * @param {String} [props.className] - estilo personalizado del selector
 * @param {readonly any[]} props.options - array de opciones
 * @param {(option: any) => string} props.getOptionLabel - parámetro que sirva de título de la opción del array de opciones
 * @param {(optuon: any) => string} [props.groupBy] - Parametro de agrupacion de la lista
 * @param {any} [props.value] - value asociado al selector
 * @param {(event: React.SyntheticEvent<Element, Event>) => void} props.onChange - función onChange del selector
 * @param {(option: any, value: any) => boolean} [props.isOptionEqualToValue] - función para comprobar si la opción y el valor hacen match para precarga de selectores
 * @param {String} [props.label] - label para el textField del selector
 * @param {String} [props.errors] - texto de error para validadores
 * @param {boolean} [props.desactivaBorrar] - icono de borrado true para desabilitar
 * @param {boolean} [props.autoHighlight] - Resalta el elemento mas parecido de la lista
 * @param {boolean} [props.autoSelect] - Forzar el elemento mas parecido de la lista
 * @param {boolean} [props.defaultValue] - Forzar el elemento mas parecido de la lista
 * @param {any} [props.color] - color del TextField
 * @param {boolean} [props.notFullWidth] - Evita que el TextField sea fullWidth
 * @param {boolean} [props.loading] - Loading flag
 * @param {boolean} [props.readOnly]
 * @param {boolean} [props.multiple] - Selector multiple o basico.
 * @param {boolean} [props.disabled] - Activar o desactivar el selector.
 */
function IngSelector(props) {
  return !props.readOnly ? (
    <Autocomplete
      multiple={props.multiple}
      className={props.className ? props.className : "textfield"}
      disablePortal={false}
      options={props.options || []}
      getOptionLabel={props.getOptionLabel}
      groupBy={props.groupBy}
      value={props.value || null}
      onChange={props.onChange}
      disableClearable={props.desactivaBorrar}
      defaultValue={props.defaultValue}
      isOptionEqualToValue={props.isOptionEqualToValue}
      loading={props.loading}
      autoHighlight={props.autoHighlight}
      autoSelect={props.autoSelect}
      fullWidth={!props.notFullWidth}
      readOnly={props.readOnly}
      disabled={props.disabled}
      renderInput={(params) => (
        <CustomTextField
          {...params}
          label={props.label}
          color={props.color || (props.readOnly && "info") || "text"}
          variant="standard"
          fullWidth={!props.notFullWidth}
          helperText={props.errors}
        />
      )}
    />
  ) : (
    <IngTextField
      className={props.className}
      value={props.value ? props.getOptionLabel(props.value) : ""}
      label={props.label}
      fullWidth={!props.notFullWidth}
      readOnly={props.readOnly}
      errors={props.errors}
    />
  );
}

export default IngSelector;
