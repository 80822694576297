import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "../../../helpers/AppContext";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import ClearIcon from "@mui/icons-material/Clear";
import IngTextField from "../../atoms/IngTextField";
import IngSelector from "../../atoms/IngSelector";
import IngButton from "../../atoms/IngButton";
import { ExerciseService } from "../../../services";
import { normalRequestBehavior, openAlert } from "../../../helpers/common";
import i18n from "../../../helpers/i18n";

function ExerciseCreateDialog({ isOpen, setClose, muscles }) {
  const lang = i18n.language;
  const { t } = useTranslation();
  const { setGlobal } = useContext(AppContext);

  const navigate = useNavigate();

  const [esName, setEsName] = useState(undefined);
  const [primaryMuscleId, setPrimaryMuscleId] = useState(undefined);

  const handleClose = () => {
    setClose();
    setEsName(undefined);
    setPrimaryMuscleId(undefined);
  };

  const handleSave = async () => {
    let exercise = {
      esName,
      primaryMuscleId,
    };

    ExerciseService.create(exercise)
      .then(normalRequestBehavior)
      .then((data) => {
        if (typeof data === "string" && data.includes("dynamic.customErrors")) {
          openAlert(setGlobal, t(data), "error");
        } else {
          navigate(`/ejercicios/${data}`);
          handleClose();
        }
      })
      .catch((err) => {
        console.error(err);
        openAlert(setGlobal, t("exercisePage.alert.createError"), "error");
      });
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>{t("exercisePage.create")}</DialogTitle>
      <DialogContent className="common-createDialog-container">
        <IngTextField
          label={`${t("common.fields.name")}:`}
          value={esName}
          onChange={(e) => setEsName(e.target.value)}
        />
        <IngSelector
          label={`${t("fields.exercise.primaryMuscle")}:`}
          options={muscles}
          getOptionLabel={(row) => row[`${lang}Name`]}
          groupBy={(row) => t(`dynamic.bodyZone.${row.bodyZone}`)}
          value={muscles?.find((row) => row.id === primaryMuscleId)}
          onChange={(e, newValue) => setPrimaryMuscleId(newValue?.id)}
        />
      </DialogContent>
      <DialogActions>
        <IngButton
          onClick={handleSave}
          disabled={!primaryMuscleId || !esName || esName.trim() === ""}
          label={t("common.confirm")}
          icon={<PanoramaFishEyeIcon />}
          color="success"
        />
        <IngButton
          onClick={() => handleClose()}
          label={t("common.cancel")}
          icon={<ClearIcon />}
          color="error"
        />
      </DialogActions>
    </Dialog>
  );
}

export default ExerciseCreateDialog;
