import React, { useEffect, useState } from "react";
import IngBuscador from "../atoms/IngBuscador";
import IngButton from "../atoms/IngButton";
import AddIcon from "@mui/icons-material/Add";
import { IconButton, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  FirstPage,
  LastPage,
  NavigateBefore,
  NavigateNext,
} from "@mui/icons-material";

/**
 * Paginacion por backend
 * @Component
 * @param {Object} props Parametros de la clase
 * @param {number} props.limit Limite de elementos que se deben mostrar por página
 * @param {function} props.setLimit El set de un useState hook que se utilizara para cambiar el limite de elementos por página
 * @param {number} props.offset Cantidad de elementos que se deben ignorar hasta llegar a la página actual
 * @param {function} props.setOffset El set de un useState hook que se utilizara para cambiar la cantidad de elementos que se deben ignorar
 * @param {number} props.total Cantidad de elementos total en la BD
 * @param {function} props.setSearch El set de un useState hook que se utilizara para la busqueda por string en la BD
 * @param {React.MouseEventHandler<HTMLButtonElement>} [props.onAddButtonClick] Funcion a realizad al pulsar ADD
 * @param {5 | 10 | 25 | 50} [props.defaultLimit] limit por defecto
 */
function IngPaginationBackend({
  limit,
  setLimit,
  offset,
  setOffset,
  total,
  setSearch,
  onAddButtonClick,
}) {
  const { t } = useTranslation();
  const [delaySearch, setDelaySearch] = useState("");

  useEffect(() => {
    const setOptionsDelay = setTimeout(() => {
      setSearch(delaySearch);
      setOffset(0);
    }, 600);
    return () => clearTimeout(setOptionsDelay);
  }, [setSearch, delaySearch, setOffset]);

  return (
    <div className="ingPagination-container">
      <div className="ingPagination-seatchAdd-container">
        <IngBuscador
          submit={(e) => {
            setDelaySearch(e.target.value);
          }}
        />
        {onAddButtonClick !== undefined && (
          <IngButton
            color="info"
            onClick={onAddButtonClick}
            variant="contained"
            label={t("common.add")}
            icon={<AddIcon />}
          />
        )}
      </div>
      <div>
        {t("dataTableProps.pagination.labelRowsPerPage")}
        <Select
          variant="standard"
          value={limit}
          onChange={(e) => {
            setLimit(Number.parseInt(e.target.value.toString()));
            setOffset(0);
          }}
        >
          <MenuItem value={5}>05</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={25}>25</MenuItem>
          <MenuItem value={50}>50</MenuItem>
        </Select>
        <IconButton disabled={offset === 0} onClick={() => setOffset(0)}>
          <FirstPage />
        </IconButton>
        <IconButton
          disabled={offset - limit < 0}
          onClick={() => setOffset((old) => old - limit)}
        >
          <NavigateBefore />
        </IconButton>
        {t("dataTableProps.pagination.labelDisplayedRows")
          .replace("{from}", (offset + 1).toString())
          .replace(
            "{to}",
            (limit + offset <= total ? limit + offset : total).toString()
          )
          .replace("{count}", total.toString())}
        <IconButton
          disabled={offset + limit > total - 1}
          onClick={() => setOffset((old) => old + limit)}
        >
          <NavigateNext />
        </IconButton>
        <IconButton
          disabled={offset + limit > total - 1}
          onClick={() => setOffset(Math.ceil(total / limit - 1) * limit)}
        >
          <LastPage />
        </IconButton>
      </div>
    </div>
  );
}

export default IngPaginationBackend;
