import * as React from "react";
import IngCheckBox from "../atoms/IngCheckBox";
import { Controller } from "react-hook-form";

/**
 * Ing Checkbox personalizado
 * @Component
 * @param {Object} props – parametros de la clase
 * @param {Object} props.control - UseForm controlle
 * @param {String} props.name - UseForm field name
 * @param {String} [props.className] - estilo personalizado del div del checkbox incluyendo su label
 * @param {String} [props.label] - texto del checkbox
 * @param {React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>} [props.onChangeCustom] - función onChange del TextField
 * @param {String} [props.errors] - mensaje de error para validadores de formularios
 * @param {boolean} [props.disabled]
 */
function IngControlledCheckBox({
  control,
  name,
  className,
  label,
  onChangeCustom,
  errors,
  disabled,
}) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <IngCheckBox
          className={className}
          label={label}
          value={value}
          onChange={(e) => {
            onChange(e);
            onChangeCustom && onChangeCustom(e);
          }}
          disabled={disabled}
          errors={errors}
        />
      )}
    />
  );
}

export default IngControlledCheckBox;
