import { MenuItem, Select } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import SpainFlag from "./SpainFlag";
import UnitedKingdomFlag from "./UnitedKingdomFlag";

function IngLanguageSelector() {
  const { i18n } = useTranslation();

  const changeLanguage = (e) => {
    const lng = e?.target?.value || "es";
    localStorage.setItem("language", lng);
    i18n.changeLanguage(lng);
  };

  return (
    <Select
      size="small"
      variant="outlined"
      value={i18n.language}
      onChange={changeLanguage}
    >
      <MenuItem value={"es"}>
        <SpainFlag className="flag-option" size="small" mode="select" />
        {"  Español"}
      </MenuItem>
      <MenuItem value={"en"}>
        <UnitedKingdomFlag className="flag-option" size="small" mode="select" />
        {"  English"}
      </MenuItem>
    </Select>
  );
}

export default IngLanguageSelector;
