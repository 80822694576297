import * as React from "react";

import Checkbox from "@mui/material/Checkbox";
import { FormControlLabel, FormHelperText } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomFormHelperText = styled(FormHelperText)(({ theme }) => ({
  "& .MuiFormHelperText-root": {
    color: theme.palette.error.main,
  },
  "& .css-1krupaq-MuiFormHelperText-root": {
    color: theme.palette.error.main,
  },
}));

/**
 * Ing Checkbox personalizado
 * @Component
 * @param {Object} props – parametros de la clase
 * @param {String} [props.className] - estilo personalizado del div del checkbox incluyendo su label
 * @param {String} [props.label] - texto del checkbox
 * @param {(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void} [props.onChange] - función para cuando cambie el valor del checkbox
 * @param {boolean} props.value - valor asociado al ckeckbox
 * @param {String} [props.errors] - mensaje de error para validadores de formularios
 * @param {boolean} [props.disabled]
 *
 */
function IngCheckBox({ className, label, onChange, value, errors, disabled }) {
  return (
    <div>
      <FormControlLabel
        className={className}
        control={<Checkbox onChange={onChange} checked={value} />}
        label={label}
        disabled={disabled}
      />
      <CustomFormHelperText>{errors}</CustomFormHelperText>
    </div>
  );
}

export default IngCheckBox;
