import Auth from "../Auth";

const baseUrl = `${window.location.protocol}//${window.location.host}`;
const isTest = true;

const defaultOrderBy = { field: "Id", ascendent: true };
const defaultLimit = 10;

const acceptedImgExtensions = {
  exercise: ["jpg", "jpeg", "png", "gif"],
  user: ["jpg", "jpeg", "png"],
};

const roles = {
  administrador: "Administrador",
  usuario: "Usuario",
};

/**
 *
 * @param {*} response - Response of the HTTP request.
 * @param {*} [enableNotFound] - If it's TRUE ? on 404 error will navigate to NotFound page : if FALSE throws error.
 * @returns
 */
function normalRequestBehavior(response, enableNotFound) {
  switch (response.status) {
    case 200:
    case 420:
      return response.json() || undefined;
    case 401:
      Auth.logout();
      break;
    case 404:
      if (enableNotFound) {
        window.location.replace("notfound");
        break;
      }
      throw new Error(response.statusText);
    default:
      throw new Error(response.statusText);
  }
}

/**
 *
 * @param {any} setGlobal
 * @param {String} msg
 * @param {"success" | "error" | "info" | "warning"} severity
 */
function openAlert(setGlobal, msg, severity) {
  if (!setGlobal || !msg || !severity) {
    return;
  }

  setGlobal((old) => ({ ...old, alert: { open: true, msg, severity } }));
}

/**
 * Funcion commun para alert de confirmacion
 * @param {Object} props
 * @param {any} props.setGlobal
 * @param {String} props.title - Titulo del alert.
 * @param {String} props.text - Texto del alert.
 * @param {function} props.onConfirm - Accion a realizar al pulsar en confirmar.
 * @param {function} [props.onCancel] - Accion a relizar al pulsar en cancelar.
 * @param {function} [props.onClose] - Accion a relizar siempre que se cierra el dialog.
 */
function openConfirmationDialog({
  setGlobal,
  title,
  text,
  onConfirm,
  onCancel,
  onClose,
}) {
  if (!setGlobal || !title || !text || !onConfirm) {
    return;
  }

  setGlobal((old) => ({
    ...old,
    confirmDialog: {
      setGlobal,
      open: true,
      title,
      text,
      onConfirm,
      onCancel: onCancel || (() => {}),
      onClose: onClose || (() => {}),
    },
  }));
}

/**
 * Constante utilizada para dar un valor por defecto al estado anterior de la tabla.
 */
const oldTableStatusDefault = {
  searchText: "",
  pageSize: 10,
  orderByCollection: [],
};

/**
 * Metodo para guardar el estado de una Material table en localStorage. (OrderBy, pageSize, searchText y filterState)
 * @param {React.MutableRefObject<any>} tableRef - Referencia asignada a la tabla.
 * @param {string} tableName - Nombre de la tabla, utilizado para la key en localStorage.
 * @param {{}} [aditionalFilters] - Filtros adicionales para la tabla (En caso de que exista).
 */
function saveMaterialTableStatus(tableRef, tableName, aditionalFilters) {
  const toStore = {
    orderByCollection: tableRef?.current?.state?.orderByCollection,
    pageSize: tableRef?.current?.state?.pageSize,
    searchText: tableRef?.current?.state?.searchText,
    aditionalFilters: aditionalFilters,
  };
  localStorage.setItem(tableName, JSON.stringify(toStore));
}

export {
  baseUrl,
  isTest,
  roles,
  acceptedImgExtensions,
  defaultOrderBy,
  defaultLimit,
  normalRequestBehavior,
  openAlert,
  openConfirmationDialog,
  oldTableStatusDefault,
  saveMaterialTableStatus,
};
