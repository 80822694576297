import { createTheme } from "@mui/material";

const colors = {
  whitesmoke: "rgb(245, 245, 245)",
  apolo: "rgb(3, 159, 148)",
  muscles: "rgb(197,77,77)",
  // Colorines de Carlitos
  exercises: "rgb(157, 45, 180)",
  unasignedOrange: "rgb(255, 120, 0)",
  tensions: "rgb(0, 198, 93)",
  unasignedYellow: "rgb(255, 192, 0)",
  unassignedRed: "rgb(221, 0, 62)",
  users: "rgb(44, 183, 255)",
  about: "rgb(113, 222, 193)",
  ingesis: "rgb(88, 39, 118)",
};

const colorsLight = {
  background: {
    paperHover: "rgb(235, 235, 235)",
    paperLabel: "rgb(200, 200, 200)",
  },
  status: {
    succes: "rgb(141, 186, 143)",
    error: "rgb(215, 65, 65)",
  },
  table: {
    info: "rgb(220, 239, 252)",
    success: "rgb(227, 252, 220)",
    warning: "rgb(255, 244, 204)",
    error: "rgb(252, 220, 220)",
  },
  const: colors,
};

const colorsDark = {
  background: {
    paperHover: "rgb(20, 20, 20)",
    paperLabel: "rgb(55, 55, 55)",
  },
  status: {
    succes: "rgb(60, 95, 65)",
    error: "rgb(98, 49, 49)",
  },
  table: {
    info: "rgb(10, 33, 41)",
    success: "rgb(11, 41, 10)",
    error: "rgb(41, 10, 10)",
    warning: "rgb(71, 69, 17)",
  },
  const: colors,
};

const themeLight = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: colors.apolo,
    },
    secondary: {
      main: "rgb(119, 86, 84)",
    },
    background: {
      paper: "rgb(241, 241, 241)",
    },
    error: {
      main: "rgb(186, 26, 26)",
    },
    success: {
      main: "rgb(55, 107, 8)",
    },
  },
});

const themeDark = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: colors.apolo /* "rgb(255, 179, 175)" */,
    },
    secondary: {
      main: "rgb(231, 189, 186)",
    },
    background: {
      paper: "rgb(14, 14, 14)",
    },
    error: {
      main: "rgb(255, 180, 171)",
    },
    success: {
      main: "rgb(155, 215, 108)",
    },
  },
});

/**
 * Given the theme mode, returns the correct Theme
 * @param {string} mode Actual theme mode can be "light" || "dark"
 * @returns {Object} Theme object
 */
const getTheme = (mode) => {
  if (mode === "dark") {
    return themeDark;
  }
  return themeLight;
};

/**
 * Given the theme mode, returns the correct ThemeColors
 * @param {string} mode Actual theme mode can be "light" || "dark"
 * @returns {Object} ThemeColors object
 */
const getThemeColors = (mode) => {
  if (mode === "dark") {
    return colorsDark;
  }
  return colorsLight;
};

/**
 * Converts any string to a unique HEX color
 * @param {string} str
 * @returns HEX color string
 */
const stringToColour = (str) => {
  if (!str) {
    return "";
  }
  let hash = 0;
  str.split("").forEach((char) => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  });
  let colour = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += value.toString(16).padStart(2, "0");
  }
  return colour;
};

export { colors, getTheme, getThemeColors, stringToColour };
