import React from "react";
import Button from "@mui/material/Button";

/**
 * Ing Botón personalizado
 * @Component
 * @param {Object} props – parametros de la clase
 * @param {String} [props.className] - estilo personalizado del botón
 * @param {"text" | "outlined" | "contained"} [props.variant] - modo visual del botón, outlined por defecto
 * @param {React.MouseEventHandler<HTMLButtonElement>} [props.onClick] - función onClick del botón
 * @param {"primary" | "secondary" | "error" | "warning" | "info" | "success" | "inherit"} [props.color] - color del botón
 * @param {React.ReactNode} [props.icon] - icono para mostrar en el botón
 * @param {React.ReactNode} [props.endIcon] - icono para mostrar en el botón
 * @param {"small" | "medium" | "large"} [props.size] - tamaño botón
 * @param {boolean} [props.disabled] - boolean que controla la habilitación/deshabilitación del botón
 * @param {String} [props.label] - texto del botón
 */
function IngButton(props) {
  return (
    <Button
      className={props.className}
      variant={props.variant ? props.variant : "contained"}
      onClick={props.onClick}
      color={props.color}
      startIcon={props.icon}
      size={props.size}
      disabled={props.disabled}
    >
      {props.label}
    </Button>
  );
}

export default IngButton;
