import * as React from "react";
import "./Login.css";
import IngButton from "../../atoms/IngButton";
import IngTextField from "../../atoms/IngTextField";
import MuiAlert from "@mui/material/Alert";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import Snackbar from "@mui/material/Snackbar";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthService } from "../../../services";
import { baseUrl } from "../../../helpers/common";
import { Paper, ThemeProvider } from "@mui/material";
import { colors, getTheme } from "../../../helpers/Colors";
import packageInfo from "../../../../package.json";

function Login() {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");

  const [bad, setBad] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState("");
  const Alert = React.forwardRef(function Alert(props, ref) {
    return (
      <MuiAlert
        elevation={6}
        ref={ref}
        variant="filled"
        {...props}
        style={{ color: colors.whitesmoke }}
      />
    );
  });

  const openAlert = (code, type) => {
    setMessage(t("loginPage.error." + code));
    setBad(true);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onClick();
    }
  };

  const onClick = () => {
    if (email && pass) {
      AuthService.login(email, pass)
        .then(async (response) => {
          if (response.status === 200) {
            return response.json();
          } else if (response.status === 401) {
            openAlert(await response.json());
          } else {
            throw new Error(response.statusText);
          }
        })
        .then((data) => {
          if (data != null) {
            localStorage.setItem("token", JSON.stringify(data));
            window.location.replace("/");
          } else {
            data && openAlert(data);
          }
        })
        .catch((error) => {
          openAlert("00");
          console.error(error);
        });
    } else {
      openAlert("01");
    }
  };

  return (
    <ThemeProvider theme={getTheme(localStorage.getItem("theme"))}>
      <div
        className="login-background"
        style={{
          background: `url('${baseUrl}/img/login-background.jpg')`,
          backgroundSize: "cover",
        }}
      >
        <Paper className="login-card">
          <img
            src={baseUrl + "/img/apolo-logo.png"}
            alt="sidebar-logo"
            className="login-logo"
          />
          <IngTextField
            color={"primary"}
            label={t("loginPage.email")}
            variant="filled"
            onChange={(t) => setEmail(t.target.value)}
            value={email}
            onKeyPress={handleKeyPress}
          />
          <IngTextField
            color={"primary"}
            label={t("loginPage.password")}
            variant="filled"
            isPassword
            onChange={(t) => setPass(t.target.value)}
            value={pass}
            onKeyPress={handleKeyPress}
          />
          <IngButton
            className="login-button"
            onClick={onClick}
            variant="contained"
            label={t("loginPage.button")}
            icon={<LoginOutlinedIcon style={{ fontSize: 24 }} />}
          />
        </Paper>
        <div
          className="login-version-number"
          style={{
            backgroundColor: colors.apolo,
            color: colors.whitesmoke,
          }}
        >
          v{packageInfo.version}
        </div>
      </div>
      {bad && (
        <Snackbar
          className="snackbar"
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          {/*@ts-ignore*/}
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            {message}
          </Alert>
        </Snackbar>
      )}
    </ThemeProvider>
  );
}

export default Login;
