import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import IngButton from "../atoms/IngButton";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import ClearIcon from "@mui/icons-material/Clear";
import { useTranslation } from "react-i18next";
import AppContext from "../../helpers/AppContext";

const defaultValues = {
  title: "",
  text: "",
  open: false,
  onConfirm: () => {
    console.warn("IngConfirmDialog: onConfirm function is not set.");
  },
  onCancel: () => {},
  onClose: () => {},
};

function IngConfirmDialog() {
  const { t } = useTranslation();
  const { global, setGlobal } = useContext(AppContext);

  useEffect(() => {
    setGlobal((old) => ({
      ...old,
      confirmDialog: defaultValues,
    }));
  }, [setGlobal]);

  const setClose = () => {
    setGlobal((old) => ({
      ...old,
      confirmDialog: { ...old.confirmDialog, open: false },
    }));
  };

  return (
    <Dialog
      open={global?.confirmDialog?.open || false}
      onClose={() => {
        global?.confirmDialog?.onClose();
        setClose();
      }}
    >
      <DialogTitle>{global?.confirmDialog?.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{global?.confirmDialog?.text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <IngButton
          onClick={() => {
            global?.confirmDialog?.onConfirm();
            global?.confirmDialog?.onClose();
            setClose();
          }}
          label={t("common.confirm")}
          icon={<PanoramaFishEyeIcon />}
          color="success"
        />
        <IngButton
          onClick={() => {
            global?.confirmDialog?.onCancel();
            global?.confirmDialog?.onClose();
            setClose();
          }}
          label={t("common.cancel")}
          icon={<ClearIcon />}
          color="error"
        />
      </DialogActions>
    </Dialog>
  );
}

export default IngConfirmDialog;
