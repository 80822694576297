import React, { useState, useEffect, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "../../../helpers/AppContext";
import { UserService } from "../../../services";
import { useTranslation } from "react-i18next";
import "./User.css";
import { stringToColour } from "../../../helpers/Colors";
import { Avatar, Button, Paper } from "@mui/material";
import IngBackdrop from "../../atoms/IngBackdrop";
import { normalRequestBehavior, openAlert } from "../../../helpers/common";
import IngPaginationBackend from "../../molecules/IngPaginationBackend";
import IngNoData from "../../atoms/IngNoData";

export default function GestionUsers() {
  const { setGlobal } = useContext(AppContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [limit, setLimit] = useState(25);
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState(0);

  const [users, setUsers] = useState([]);

  const getUsers = useCallback(() => {
    setLoading(false);
    UserService.getFiltered({ limit, offset, search })
      .then(normalRequestBehavior)
      .then((data) => {
        if (data) {
          setUsers(data.exercises);
          setTotal(data.total);
        }
      })
      .catch((err) => {
        console.error(err);
        openAlert(setGlobal, t("userPage.listPage.alert.getError"), "error");
      })
      .finally(() => setLoading(false));
  }, [limit, offset, search, setGlobal, t]);

  useEffect(() => {
    setGlobal((prev) => ({ ...prev, pageTitle: t("userPage.listPage.title") }));
    getUsers();
  }, [getUsers, setGlobal, t]);

  return (
    <IngBackdrop containerTag="div" loading={loading}>
      <IngPaginationBackend
        limit={limit}
        setLimit={setLimit}
        offset={offset}
        setOffset={setOffset}
        total={total}
        setSearch={setSearch}
      />
      {users?.length > 0 ? (
        <div className="card-list-backdrop">
          {users.map((row, i) => (
            <Paper key={i}>
              <Button
                className="user-card"
                onClick={() => navigate(`/usuarios/${row.id}`)}
                color="inherit"
              >
                <Avatar
                  className="user-avatar"
                  style={{
                    backgroundColor: stringToColour(row.email),
                  }}
                  src={row.profileImage?.base64}
                >
                  {row.fullName.split(" ").map((row) => row[0] || "U")}
                </Avatar>
                <div className="user-card-text">
                  <b>{row.fullName}</b>
                  <div className="user-card-text-name">{row.email}</div>
                </div>
              </Button>
            </Paper>
          ))}
        </div>
      ) : (
        <IngNoData />
      )}
    </IngBackdrop>
  );
}
