import * as React from "react";
import Slider from "@mui/material/Slider";
import { InputLabel } from "@mui/material";

/**
 * Ing Slider personalizado
 * @Component
 * @param {Object} props – parametros de la clase
 * @param {String} [props.className] - estilo personalizado del slider
 * @param {String} [props.name] - Nombre el input
 * @param {String} [props.label] - label del slider
 * @param {number | number[]} [props.defaultValue] - valor por defecto
 * @param {number | number[]} [props.value] - valor asociado al slider
 * @param {number} [props.min] - valor mínimo del slider (no puede ser igual que el Max)
 * @param {number} [props.max] - valor máximo del slider (no puede ser igual que el Min)
 * @param {(event: Event, value: number | number[], activeThumb: number) => void} [props.onChange] - función onChange del slider
 */
function IngSlider(props) {
  return (
    <div className={props.className ? props.className : ""}>
      <InputLabel>
        {props.label} <b>{props.value || 0}</b>
      </InputLabel>
      <Slider
        name={props.name}
        defaultValue={props.defaultValue}
        valueLabelDisplay="auto"
        value={props.value || 0}
        min={props.min}
        max={props.max}
        onChange={props.onChange}
      />
    </div>
  );
}

export default IngSlider;
