import React, { useEffect, useContext } from "react";
import AppContext from "../../../helpers/AppContext";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import "./Home.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Paper } from "@mui/material";
import { colors } from "../../../helpers/Colors";
import Auth from "../../../Auth";
import { roles } from "../../../helpers/common";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import ExpandIcon from "@mui/icons-material/Expand";

function Home() {
  const { setGlobal } = useContext(AppContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userRoles = Auth.getRoles();

  useEffect(() => {
    setGlobal((prev) => ({ ...prev, pageTitle: t("homePage.title") }));
  }, [setGlobal, t]);

  return (
    <div className="home-button-list">
      <Paper>
        <Button
          className="home-button"
          onClick={() => navigate("/ejercicios")}
          color="inherit"
        >
          <DirectionsRunIcon
            className="home-button-icon"
            style={{ color: colors.exercises }}
          />
          <div className="home-button-text">
            {t("homePage.buttons.exercises")}
          </div>
        </Button>
      </Paper>
      <Paper>
        <Button
          className="home-button"
          onClick={() => navigate("/musculos")}
          color="inherit"
        >
          <AccessibilityNewIcon
            className="home-button-icon"
            style={{ color: colors.muscles }}
          />
          <div className="home-button-text">
            {t("homePage.buttons.muscles")}
          </div>
        </Button>
      </Paper>
      <Paper>
        <Button
          className="home-button"
          onClick={() => navigate("/tensiones")}
          color="inherit"
        >
          <ExpandIcon
            className="home-button-icon"
            style={{ color: colors.tensions }}
          />
          <div className="home-button-text">
            {t("homePage.buttons.tensions")}
          </div>
        </Button>
      </Paper>
      <Paper>
        {userRoles.includes(roles.administrador) && (
          <Button
            className="home-button"
            onClick={() => navigate("/usuarios")}
            color="inherit"
          >
            <GroupsOutlinedIcon
              className="home-button-icon"
              style={{ color: colors.users }}
            />
            <div className="home-button-text">
              {t("homePage.buttons.users")}
            </div>
          </Button>
        )}
      </Paper>
    </div>
  );
}

export default Home;
