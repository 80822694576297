function getAll() {
  const token = localStorage.getItem("token");
  let accessToken = JSON.parse(token);
  return fetch("/api/exercise", {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken["token"]}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

function getFiltered({ limit, offset, search = "", orderBy }) {
  const token = localStorage.getItem("token");
  let accessToken = JSON.parse(token);

  return fetch(`/api/exercise/table/filtered`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken["token"]}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      limit,
      offset,
      search,
      orderBy: orderBy?.field,
      ascendent: orderBy?.ascendent,
    }),
  });
}

function get(id) {
  const token = localStorage.getItem("token");
  let accessToken = JSON.parse(token);
  return fetch(`/api/exercise/${id}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken["token"]}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

async function create(exercise) {
  const token = localStorage.getItem("token");
  let accessToken = JSON.parse(token);

  return fetch(`/api/exercise`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken["token"]}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(exercise),
  });
}

async function update(id, exercise) {
  const token = localStorage.getItem("token");
  let accessToken = JSON.parse(token);

  return fetch(`/api/exercise/${id}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${accessToken["token"]}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(exercise),
  });
}

async function remove(id) {
  const token = localStorage.getItem("token");
  let accessToken = JSON.parse(token);

  return fetch(`/api/exercise/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${accessToken["token"]}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export { getAll, getFiltered, get, create, update, remove };
