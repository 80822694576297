import React, { useState, useContext } from "react";
import "./main.css";
import { CssBaseline, IconButton, ThemeProvider } from "@mui/material";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { useNavigate } from "react-router-dom";
import AppContext from "../../helpers/AppContext";
import Tooltip from "@mui/material/Tooltip";
import { baseUrl, isTest, roles } from "../../helpers/common";
import Auth from "../../Auth";
import { useTranslation } from "react-i18next";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MenuIcon from "@mui/icons-material/Menu";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import IngThemeSwitch from "../atoms/IngThemeSwitch";
import { getTheme, getThemeColors } from "../../helpers/Colors";
import IngAlert from "../atoms/IngAlert";
import IngConfirmDialog from "../molecules/IngConfirmDialog";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import IngLanguageSelector from "../atoms/IngLanguageSelector";
import ExpandIcon from "@mui/icons-material/Expand";

function Main({ children, userRoles }) {
  const { global } = useContext(AppContext);
  const [themeMode, setThemeMode] = useState(localStorage.getItem("theme"));
  const theme = getTheme(themeMode);
  const themeColor = getThemeColors(themeMode);

  const { t } = useTranslation();

  const [menuCollapse, setMenuCollapse] = useState(true);
  const navigate = useNavigate();

  const changeTheme = () => {
    let newTheme = themeMode === "light" ? "dark" : "light";
    localStorage.setItem("theme", newTheme);
    setThemeMode(newTheme);
  };

  return (
    <div id="page">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Sidebar
          collapsed={menuCollapse}
          backgroundColor={theme.palette.background.paper}
          className="sidebar"
        >
          <Menu
            menuItemStyles={{
              button: {
                height: "65px",
                cursor: "default",
                backgroundColor: menuCollapse
                  ? theme.palette.primary.main
                  : theme.palette.background.paper,
                transition: "background-color 1s ease",
                ":hover": {
                  backgroundColor: menuCollapse
                    ? theme.palette.primary.main
                    : theme.palette.background.paper,
                  transition: "background-color 1s ease",
                },
              },
            }}
          >
            <MenuItem
              rootStyles={{
                zIndex: 1000,
                position: "fixed",
                top: 0,
                left: 0,
                width: menuCollapse ? "80px" : "250px",
                textAlign: "left",
                transition: "width 0.3s ease",
              }}
              icon={
                menuCollapse ? (
                  <MenuIcon
                    onClick={() => setMenuCollapse(!menuCollapse)}
                    style={{
                      color: themeColor.const.whitesmoke,
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <>
                    <img
                      src={baseUrl + "/img/apolo-logo.png"}
                      alt="APOLO"
                      className="sidebar-logo"
                    />
                    <IconButton
                      aria-label="Hide Menu"
                      onClick={() => setMenuCollapse(!menuCollapse)}
                      style={{ color: theme.palette.primary.main }}
                    >
                      <ArrowBackIosNewIcon />
                    </IconButton>
                  </>
                )
              }
            />
          </Menu>

          <Menu
            menuItemStyles={{
              button: {
                "&.ps-active": {
                  color: theme.palette.primary.light,
                },
                ":hover": {
                  backgroundColor: themeColor.background.paperHover,
                },
              },
            }}
          >
            <Tooltip
              title={menuCollapse && t("main.sidebar.home")}
              placement="right"
              arrow
            >
              <div>
                <MenuItem
                  active={window.location.pathname === "/"}
                  icon={<HomeOutlinedIcon className="sidebar-icon" />}
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <div
                    className="sidebar-text"
                    style={{ color: theme.palette.text.secondary }}
                  >
                    {t("main.sidebar.home")}
                  </div>
                </MenuItem>
              </div>
            </Tooltip>

            <Tooltip
              title={menuCollapse && t("main.sidebar.exercises")}
              placement="right"
              arrow
              onClick={() => {
                navigate("/ejercicios");
              }}
            >
              <div>
                <MenuItem
                  active={window.location.pathname.includes("/ejercicios")}
                  icon={<DirectionsRunIcon className="sidebar-icon" />}
                >
                  <div
                    className="sidebar-text"
                    style={{ color: theme.palette.text.secondary }}
                  >
                    {t("main.sidebar.exercises")}
                  </div>
                </MenuItem>
              </div>
            </Tooltip>

            <Tooltip
              title={menuCollapse && t("main.sidebar.muscles")}
              placement="right"
              arrow
              onClick={() => {
                navigate("/musculos");
              }}
            >
              <div>
                <MenuItem
                  active={window.location.pathname.includes("/musculos")}
                  icon={<AccessibilityNewIcon className="sidebar-icon" />}
                >
                  <div
                    className="sidebar-text"
                    style={{ color: theme.palette.text.secondary }}
                  >
                    {t("main.sidebar.muscles")}
                  </div>
                </MenuItem>
              </div>
            </Tooltip>

            <Tooltip
              title={menuCollapse && t("main.sidebar.tensions")}
              placement="right"
              arrow
              onClick={() => {
                navigate("/tensiones");
              }}
            >
              <div>
                <MenuItem
                  active={window.location.pathname.includes("/tensiones")}
                  icon={<ExpandIcon className="sidebar-icon" />}
                >
                  <div
                    className="sidebar-text"
                    style={{ color: theme.palette.text.secondary }}
                  >
                    {t("main.sidebar.tensions")}
                  </div>
                </MenuItem>
              </div>
            </Tooltip>

            {userRoles.includes(roles.administrador) && (
              <Tooltip
                title={menuCollapse && t("main.sidebar.users")}
                placement="right"
                arrow
                onClick={() => {
                  navigate("/usuarios");
                }}
              >
                <div>
                  <MenuItem
                    active={window.location.pathname.includes("/usuarios")}
                    icon={<GroupsOutlinedIcon className="sidebar-icon" />}
                  >
                    <div
                      className="sidebar-text"
                      style={{ color: theme.palette.text.secondary }}
                    >
                      {t("main.sidebar.users")}
                    </div>
                  </MenuItem>
                </div>
              </Tooltip>
            )}
            <Tooltip
              title={menuCollapse && t("main.sidebar.about")}
              placement="right"
              arrow
              onClick={() => {
                navigate("/about");
              }}
            >
              <div>
                <MenuItem
                  active={window.location.pathname.includes("/about")}
                  icon={<LightbulbOutlinedIcon className="sidebar-icon" />}
                >
                  <div
                    className="sidebar-text"
                    style={{ color: theme.palette.text.secondary }}
                  >
                    {t("main.sidebar.about")}
                  </div>
                </MenuItem>
              </div>
            </Tooltip>
          </Menu>
          <Menu
            menuItemStyles={{
              button: {
                height: "65px",
                ":hover": {
                  backgroundColor: themeColor.background.paperHover,
                },
              },
            }}
          >
            <Tooltip
              title={menuCollapse && t("main.sidebar.logout")}
              placement="right"
              followCursor
            >
              <div>
                <MenuItem
                  rootStyles={{
                    zIndex: 1000,
                    position: "fixed",
                    width: menuCollapse ? "80px" : "250px",
                    bottom: 0,
                    left: 0,
                    textAlign: "left",
                    transition: "width 0.3s ease",
                    backgroundColor: theme.palette.background.paper,
                    borderTop: 20,

                    borderColor: "pink",
                  }}
                  icon={<ExitToAppOutlinedIcon className="sidebar-icon" />}
                  onClick={() => {
                    Auth.logout();
                  }}
                >
                  {menuCollapse ? null : (
                    <div
                      className="sidebar-text"
                      style={{ color: theme.palette.text.secondary }}
                    >
                      {t("main.sidebar.logout")}
                    </div>
                  )}
                </MenuItem>
              </div>
            </Tooltip>
          </Menu>
        </Sidebar>
        <main id="main">
          <nav
            className="navbar"
            style={{ backgroundColor: theme.palette.background.paper }}
          >
            {isTest && (
              <div className="navbar-back">
                <IconButton
                  aria-label="Hide Menu"
                  onClick={() => {
                    navigate(-1);
                  }}
                  style={{ color: theme.palette.text.primary }}
                >
                  <ArrowBackIcon />
                </IconButton>
              </div>
            )}
            <div
              className="navbar-title"
              style={{ color: theme.palette.text.primary }}
            >
              {global.pageTitle}
            </div>
            <div className="navbar-actions">
              <IngLanguageSelector />
              <IngThemeSwitch
                onClick={() => changeTheme()}
                checked={themeMode === "dark"}
              />
            </div>
          </nav>
          <div id="appContent">
            {children}
            <IngAlert />
            <IngConfirmDialog />
            <div className="testversion-message">
              <InfoOutlinedIcon />
              TEST VERSION
            </div>
          </div>
        </main>
      </ThemeProvider>
    </div>
  );
}
export default Main;
