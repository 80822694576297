import React from "react";
import IngBackdrop from "../atoms/IngBackdrop";
import { Card, Tab, Tabs, useTheme } from "@mui/material";
import { useState } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const icons = {
  success: <CheckCircleOutlineIcon />,
  info: <InfoOutlinedIcon />,
  warning: <WarningAmberRoundedIcon />,
  error: <ErrorOutlineIcon />,
};

/**
 * Elemento Tabs para las ventanas principales
 * @Component
 * @param {Object} props Parametros de la clase
 * @param {Array<{icon, label, content, hidden?, disabled?}>} props.tabs Etiqueta usada para crear el contenedor, ya sea un div de HTML o una Card de Material UI
 * @param {boolean} [props.loading] TRUE: Pestañas en disables y B
 * @param {string} [props.message] Message on the bottom right of the page.
 * @param {"success" | "info" | "warning"  |"error"} [props.messageType] Color for the bottom right message.
 * @param {boolean} [props.hasMemory] TRUE: Las pesatañas se cargaran una unica vez cuando se pulsen // FALSE: Las pestañas se cargaran cada vez que se pulsen
 */
function IngTabs({ tabs, loading, message, messageType, hasMemory }) {
  const [tab, setTab] = useState(0);
  const [loaded, setLoaded] = useState([0]);
  const theme = useTheme();

  const onTabClick = (e, newVal) => {
    setTab(newVal);
    !loaded.includes(newVal) && setLoaded((old) => [...old, newVal]);
  };

  return (
    <>
      <Card className="ingtabs-tabs-container">
        <Tabs
          className="ingtabs-tabs"
          value={tab}
          onChange={onTabClick}
          variant="scrollable"
        >
          {tabs.map(
            (row, index) =>
              !row.hidden && (
                <Tab
                  key={index}
                  className={"ingtabs-tab"}
                  iconPosition="start"
                  icon={row.icon}
                  label={row.label}
                  value={index}
                  style={{
                    width: `${100 / tabs.filter((row) => !row.hidden).length}%`,
                    color: index === tab && theme.palette.primary.contrastText,
                    backgroundColor:
                      index === tab && theme.palette.primary.main,
                  }}
                  hidden={row.hidden}
                  disabled={row.disabled || loading}
                />
              )
          )}
        </Tabs>
      </Card>
      {message && (
        <div
          className="ingtabs-message"
          style={{
            backgroundColor: theme.palette[messageType || "info"].main,
            color: theme.palette[messageType || "info"].contrastText,
          }}
        >
          {icons[messageType || "info"]}
          {message}
        </div>
      )}
      {hasMemory ? (
        <IngBackdrop loading={loading}>
          {tabs.map(
            (row, index) =>
              loaded.includes(index) && (
                <div className="ingtabs-tabs-content" hidden={index !== tab}>
                  {row.content}
                </div>
              )
          )}
        </IngBackdrop>
      ) : (
        <IngBackdrop loading={loading} className="ingtabs-tabs-content">
          {tabs[tab].content}
        </IngBackdrop>
      )}
    </>
  );
}

export default IngTabs;
