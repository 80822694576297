function getAll() {
  const token = localStorage.getItem("token");
  let accessToken = JSON.parse(token);
  return fetch("/api/muscle", {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken["token"]}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

function getSelector() {
  const token = localStorage.getItem("token");
  let accessToken = JSON.parse(token);
  return fetch("/api/muscle/selector", {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken["token"]}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

async function update(id, muscle) {
  const token = localStorage.getItem("token");
  let accessToken = JSON.parse(token);

  return fetch(`/api/muscle/${id}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${accessToken["token"]}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(muscle),
  });
}

export { getAll, getSelector, update };
